import React, { useContext, useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

type FinancingConfig = {
  yearlyRent: number;
  durationYears: number;
  provider: 'warmtefonds' | 'lenderSpender';
};
type ContextValue = [FinancingConfig, Dispatch<SetStateAction<FinancingConfig>> | null];

export const LENDER_SPENDER_RENT = 0.075; // https://www.lenderspender.nl/link/energiebespaarders

/**
 * 	Voor bedragen van € 1.000 tot € 14.999 zijn de mogelijke looptijden 7, 10 of 15 jaar.
 •	Voor bedragen van € 15.000 tot € 27.000 zijn de mogelijke looptijden 7, 10, 15 of 20 jaar.
 •	Voor bedragen van € 27.001 tot € 71.000 (voor ZEP of ZEP+/Nul op de Meter) zijn de mogelijke looptijden 7, 10, 15 of 20 jaar.
 •	Deze tabel is voor het laatst bijgewerkt op 28 december 2023.
 */
export const warmtefondsRentPerDuration: Record<number, number> = {
  7: 0.0375,
  10: 0.038,
  15: 0.0415,
  20: 0.042,
} as const;

const initialFinancingConfig: FinancingConfig = {
  yearlyRent: warmtefondsRentPerDuration[15],
  durationYears: 15,
  provider: 'warmtefonds',
};

export const FinancingConfigContext = React.createContext<ContextValue>([
  initialFinancingConfig,
  null,
]);

export const FinancingConfigProvider: React.FC = ({ children }) => {
  const [financingConfig, setFinancingConfig] = useState(initialFinancingConfig);
  const contextValue: ContextValue = [financingConfig, setFinancingConfig];
  return (
    <FinancingConfigContext.Provider value={contextValue}>
      {children}
    </FinancingConfigContext.Provider>
  );
};

export function useFinancingConfig(configOverrides?: Partial<FinancingConfig>) {
  const [financingConfig, setFinancingConfig] = useContext(FinancingConfigContext);

  useEffect(() => {
    if (configOverrides) {
      setFinancingConfig?.({ ...financingConfig, ...configOverrides });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configOverrides, setFinancingConfig]);

  const handleSetFinancingConfig = (config: Partial<FinancingConfig>) => {
    setFinancingConfig?.({
      ...financingConfig,
      ...config,
      ...(config.durationYears && financingConfig.provider === 'warmtefonds'
        ? {
            yearlyRent:
              warmtefondsRentPerDuration[
                config.durationYears as keyof typeof warmtefondsRentPerDuration
              ],
          }
        : {}),
    });
  };

  return { financingConfig, setFinancingConfig: handleSetFinancingConfig };
}

// Warmtefonds only offers 20 year loans for amounts above €15.000
export const MIN_LOAN_FOR_20_YEARS = 15000;

// Warmtefonds caps their loans at €28.000
export const MAX_LOAN = 28000;

export function useFinancing(
  investment: number,
  { useMonths = true, rounding = 1, addEbvMargin = false } = {},
): { totalLoan: number; payments: number; annuity: number } {
  const {
    financingConfig: { yearlyRent, durationYears },
    setFinancingConfig,
  } = useFinancingConfig();

  const cappedInvestment = Math.min(MAX_LOAN, investment);

  useEffect(() => {
    // If the investment is below the minimum for a 20 year loan, set the duration to 15 years
    if (investment < MIN_LOAN_FOR_20_YEARS && durationYears === 20) {
      setFinancingConfig({ durationYears: 15 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment]);

  const loan = Math.ceil((cappedInvestment * (addEbvMargin ? 1.2 : 1)) / rounding) * rounding;

  const rent = useMonths ? yearlyRent / 12 : yearlyRent;
  const payments = useMonths ? durationYears * 12 : durationYears;

  return {
    payments,
    totalLoan: loan,
    annuity: loan * (rent / (1 - Math.pow(1 + rent, -1 * payments))),
  };
}
