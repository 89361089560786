import { Box, Button, Flex, Separator } from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import { BadgeId, Hardhat } from '@energiebespaarders/symbols/icons/solid';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useFlag } from 'react-unleash-flags';
import styled, { css } from 'styled-components';
import { getActiveHouseId, identifyMe } from '~/components/_app/initializeSession';
import type { Environment } from '~/config';
import config from '~/config';
import { useAuthModal } from '~/hooks/useAuthModal';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import { useMeOptional } from '../../hooks/useMe';
import { boxShadow } from '../../styles/mixins';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import type { me_me_Customer, me_me_Lead } from '../../types/generated/me';

export const Form = styled.div`
  ${boxShadow('md')};
  margin-bottom: 0 !important;
  max-width: 100%;
  border: 0;
  border-bottom: ${x => `1px solid ${x.theme.colors.grayLight}`};
  background-color: ${x => x.theme.colors.grayLighter};
  border-radius: 3px;
  overflow: hidden;

  ${mediaMin.md} {
    border: ${x => `1px solid ${x.theme.colors.grayLight}`};
  }
`;

export const Tab = styled(Box)<{ active?: boolean }>`
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-weight: bold;
  color: ${x => x.theme.colors.grayDark};
  text-align: center;
  background-color: ${x => `1px solid ${x.theme.colors.grayLighter}`};
  transition: all 0.15s;

  &:first-of-type {
    border-right: ${x => `1px solid ${x.theme.colors.grayLight}`};
  }

  ${x =>
    !x.active &&
    css`
      border-bottom: ${x => `1px solid ${x.theme.colors.grayLight}`};
      background-color: white;
      color: ${x => x.theme.colors.gray};

      &:hover {
        color: ${x => x.theme.colors.green};
      }
    `}
`;

const proPortalUrls: Record<Environment, string> = {
  development: 'http://localhost:3001/login',
  staging: 'https://pro-staging.energiebespaarders.nl/login',
  production: 'https://pro.energiebespaarders.nl',
};

export type AuthFormType = 'login' | 'forgot';

type AuthenticationFormsProps = {
  defaultForm: AuthFormType;
};

const initialMessage =
  'Meld je aan om jouw resultaten en gegevens veilig op te slaan en om offertes in jouw account te ontvangen. Je kunt vanuit jouw account altijd de volgende stap nemen.';

const AuthenticationForms: React.FC<AuthenticationFormsProps> = ({ defaultForm = 'login' }) => {
  const router = useRouter();
  const { me, setMe } = useMeOptional<me_me_Lead | me_me_Customer>();
  const [currentForm, setCurrentForm] = useState<AuthFormType>(defaultForm);
  const [title, setTitle] = useState('Welkom terug! 😄');
  const [message, setMessage] = useState(initialMessage);
  const { activeHouseId, setActiveHouseId } = useActiveHouseId();
  // Global state for email, so it can stay filled when switching forms
  const [email, setEmail] = useState('');
  const toggleAuthModal = useAuthModal();

  const isProPortalLinkEnabled = useFlag('link-to-pro-portal');

  /**
   * Set the new title and message the for the modal depending on the form that is being shown
   */
  useEffect(() => {
    let newTitle = '';
    let newMessage = '';
    if (currentForm === 'forgot') {
      newTitle = 'Nieuw wachtwoord instellen';
      newMessage =
        'Wachtwoord vergeten of nog niet ingesteld? Geen probleem! Vul hieronder je e-mailadres in en we sturen je een link om je wachtwoord direct in te stellen.';
    } else if (currentForm === 'login') {
      newTitle = 'Welkom terug! 😄';
      newMessage =
        'Vul je e-mailadres in om een magic link te ontvangen. Hiermee log je in één klik in. ✨';
    }
    setTitle(newTitle);
    setMessage(newMessage);
  }, [currentForm]);

  /**
   * When me is refetched, reset the logrocket stuff
   */
  useEffect(() => {
    // Identify with services after succesfully logging in (typename is customer)
    if (me?.__typename === 'Customer') {
      identifyMe(me);
      setMe(me);
      const myHouseId = getActiveHouseId(me);
      setActiveHouseId(myHouseId);
    }
  }, [activeHouseId, me, setMe, setActiveHouseId]);

  const toggleForgotForm = useCallback(() => setCurrentForm('forgot'), []);
  const handleNoAccount = useCallback(() => {
    // Redirect to intake to start the flow for creating a new account
    router.push('/intake').catch(console.error);
    // Ensure the modal is closed after being redirected
    toggleAuthModal(false);
  }, [router, toggleAuthModal]);

  return (
    <Form>
      <Box p={5}>
        <Heading heading="h3" className="text-center">
          {title}
        </Heading>
        <p className="text-center">{message}</p>
        <Separator my={3} color="grayLight" />

        <Flex flexWrap="wrap" justifyContent="center" mx={-1}>
          {currentForm === 'login' && (
            <>
              <LoginForm showForgotForm={toggleForgotForm} email={email} setEmail={setEmail} />

              <Box width={[1, 1, 1 / 2]} px={1} textAlign="right" mt={1}>
                <Button
                  minimal
                  fluid
                  to="/samen/login"
                  onClick={() => toggleAuthModal(false)}
                  iconStart={BadgeId}
                  label="Inloggen als adviseur"
                  px={5}
                  mr={0}
                  mb={0}
                  style={{ borderRadius: 3 }}
                />
              </Box>
              <Box width={[1, 1, 1 / 2]} px={1} mt={1}>
                <Button
                  minimal
                  fluid
                  href={
                    isProPortalLinkEnabled?.enabled
                      ? proPortalUrls[config.environment as Environment] || proPortalUrls.production
                      : 'https://partner.energiebespaarders.nl'
                  }
                  onClick={() => toggleAuthModal(false)}
                  target="_self"
                  iconStart={Hardhat}
                  label="Inloggen als uitvoerpartner"
                  px={5}
                  mr={0}
                  mb={0}
                  style={{ borderRadius: 3 }}
                />
              </Box>
              <Box width={1} textAlign="center" mt={5}>
                <div className="link-gray small" onClick={handleNoAccount}>
                  Nog geen account? Start hier!
                </div>
                <div className="link-gray small" onClick={toggleForgotForm}>
                  Nog geen wachtwoord of wachtwoord vergeten?
                </div>
              </Box>
            </>
          )}
        </Flex>

        {currentForm === 'forgot' && (
          <ForgotPasswordForm
            cancel={() => setCurrentForm('login')}
            email={email}
            setEmail={setEmail}
          />
        )}
      </Box>
    </Form>
  );
};

export default AuthenticationForms;
