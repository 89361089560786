import React, { useCallback, useContext, useState } from 'react';
import type { ContactFormPreset } from '~/components/ContactModal';
import ContactModal from '~/components/ContactModal';
import type { ContactCategory, ContactSubject } from '~/types/graphql-global-types';

export type ContactFormValues = {
  category?: ContactCategory;
  subject?: ContactSubject;
  message: string;
};
type ContextValue = {
  setContactModalContent: (modalContent: ContactModalContent) => void;
  prefillContactModal: (values: ContactFormPreset) => void;
};

export const ContactModalContext = React.createContext<ContextValue>({
  setContactModalContent: () => null,
  prefillContactModal: () => null,
});

export type ContactModalContent = 'contactForm' | 'preContact' | '';

export const ContactModalProvider: React.FC = ({ children }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [contactModalPreset, setContactModalPreset] = useState<ContactFormPreset>();
  const [content, setContent] = useState<ContactModalContent>('');

  const setContactModalContent = (modalContent: ContactModalContent) => {
    setContactModalOpen(!contactModalOpen);
    setContent(modalContent);
  };
  const closeContactModal = useCallback(() => {
    setContactModalPreset(undefined);
    setContactModalOpen(false);
  }, []);

  const prefillContactModal = (values?: ContactFormPreset) => {
    setContactModalPreset(values);
  };
  return (
    <ContactModalContext.Provider value={{ setContactModalContent, prefillContactModal }}>
      {children}
      <ContactModal
        isOpen={contactModalOpen}
        closeModal={closeContactModal}
        preset={contactModalPreset}
        content={content}
        setContent={setContent}
      />
    </ContactModalContext.Provider>
  );
};

export function useContactModal() {
  const { setContactModalContent, prefillContactModal } = useContext(ContactModalContext);
  return { setContactModalContent, prefillContactModal };
}
