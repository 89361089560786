import config from '~/config';

/* These are the GTM snippets for the different environments of the same workspace (production and staging)
 * NOTE: The production environment can be previewed with `&gtm_auth=DpUCUs3L88L2ilBymQxb_w&gtm_preview=env-2&gtm_cookies_win=x`, but this enables debug mode, so it's not recommended for production.
 */
export const containerEnvSnippet =
  config.environment === 'production'
    ? ''
    : config.environment === 'staging'
    ? '&gtm_auth=CmYo4GB2XRnszTZYPQTu5Q&gtm_preview=env-7&gtm_cookies_win=x'
    : '&gtm_auth=cuYF-UmJprQOR0NbnNq2Cw&gtm_preview=env-328&gtm_cookies_win=x';
