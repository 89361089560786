import { Modal } from '@energiebespaarders/symbols';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import React, { useCallback, useContext, useState } from 'react';
import AuthenticationForms from '../components/AuthenticationForms';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import type { AuthFormType } from '../components/AuthenticationForms';

type ContextValue = {
  toggleAuthModal: (authModalOpen: boolean, defaultForm?: AuthFormType) => void;
};

export const AuthModalContext = React.createContext<ContextValue>({ toggleAuthModal: () => null });

export const AuthModalProvider: React.FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const mobile = useIsMobile();
  const [defaultForm, setDefaultForm] = useState<AuthFormType>('login');
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const toggleAuthModal = useCallback(
    (authModalOpen = true, defaultForm: AuthFormType = 'login') => {
      if (defaultForm) setDefaultForm(defaultForm);
      setAuthModalOpen(authModalOpen);
    },
    [],
  );
  const closeAuthModal = useCallback(() => {
    setAuthModalOpen(false);
  }, []);
  return (
    <AuthModalContext.Provider value={{ toggleAuthModal }}>
      {children}
      <Modal
        contentPadding={0}
        height="auto"
        mobile={mobile}
        onRequestClose={closeAuthModal}
        isOpen={authModalOpen && !isAuthenticated.asCustomer}
      >
        <AuthenticationForms defaultForm={defaultForm} />
      </Modal>
    </AuthModalContext.Provider>
  );
};

export function useAuthModal() {
  const { toggleAuthModal } = useContext(AuthModalContext);
  return toggleAuthModal;
}
