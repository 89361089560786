/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnswerType {
  boolean = "boolean",
  booleanArray = "booleanArray",
  date = "date",
  dateArray = "dateArray",
  number = "number",
  numberArray = "numberArray",
  string = "string",
  stringArray = "stringArray",
}

export enum Appendix {
  bathroomRenovation = "bathroomRenovation",
  calculations = "calculations",
  energyLabels = "energyLabels",
  energyNeutral = "energyNeutral",
  energySavingTips = "energySavingTips",
  heating = "heating",
  ledLighting = "ledLighting",
  radiators = "radiators",
  registerLabel = "registerLabel",
  ventilation = "ventilation",
  weatherStripping = "weatherStripping",
}

export enum Color {
  black = "black",
  blue = "blue",
  brown = "brown",
  darkBlue = "darkBlue",
  gray = "gray",
  green = "green",
  orange = "orange",
  purple = "purple",
  red = "red",
  silver = "silver",
  white = "white",
}

export enum ContactCategory {
  complaint = "complaint",
  feedback = "feedback",
  partnership = "partnership",
  question = "question",
  technicalIssue = "technicalIssue",
}

export enum ContactSubject {
  accountIssue = "accountIssue",
  adviceQuestion = "adviceQuestion",
  emailIssue = "emailIssue",
  energyLabelQuestion = "energyLabelQuestion",
  financialPartner = "financialPartner",
  generalComplaint = "generalComplaint",
  generalServiceQuestion = "generalServiceQuestion",
  installationFeedback = "installationFeedback",
  installerComplaint = "installerComplaint",
  installerPartner = "installerPartner",
  otherFeedback = "otherFeedback",
  otherIssue = "otherIssue",
  otherPartner = "otherPartner",
  otherQuestion = "otherQuestion",
  planningQuestion = "planningQuestion",
  serviceComplaint = "serviceComplaint",
  serviceFeedback = "serviceFeedback",
  subsidyQuestion = "subsidyQuestion",
  websiteFeedback = "websiteFeedback",
}

export enum CustomerLoginError {
  Default = "Default",
  InvalidCredentials = "InvalidCredentials",
  MagicLinkExpired = "MagicLinkExpired",
  NoPlatformAccess = "NoPlatformAccess",
}

export enum CustomerSatisfactionQuestionType {
  likert = "likert",
  multiline = "multiline",
  rating = "rating",
  text = "text",
  thumbs = "thumbs",
  yesno = "yesno",
}

export enum DiscountType {
  amountInclTax = "amountInclTax",
  percentageInclTax = "percentageInclTax",
}

export enum DurationUnit {
  days = "days",
  hours = "hours",
  minutes = "minutes",
  seconds = "seconds",
}

export enum DutyName {
  cashback = "cashback",
  energiepaspoort = "energiepaspoort",
  energyIndex = "energyIndex",
  energyLabel = "energyLabel",
  fullElectricAdvice = "fullElectricAdvice",
  nomAdvice = "nomAdvice",
  quoteDiscount = "quoteDiscount",
  subsidy = "subsidy",
  taxation = "taxation",
  virtualVisit = "virtualVisit",
  visit = "visit",
}

export enum DutyPackageName {
  CMISComplete = "CMISComplete",
  CMISDirect = "CMISDirect",
  CMISSimple = "CMISSimple",
  RISKSimple = "RISKSimple",
  RISKSimpleGreen = "RISKSimpleGreen",
  analyseComplete = "analyseComplete",
  analyseSimple = "analyseSimple",
  asrComplete = "asrComplete",
  budgetThuisComplete = "budgetThuisComplete",
  budgetThuisNoIntake = "budgetThuisNoIntake",
  budgetThuisTelesales = "budgetThuisTelesales",
  compareWithIsaFree = "compareWithIsaFree",
  complete = "complete",
  consumentenbondComplete = "consumentenbondComplete",
  dakComplete = "dakComplete",
  dataFanaticsSimple = "dataFanaticsSimple",
  direct = "direct",
  drutenWijchen = "drutenWijchen",
  easySwitchComplete = "easySwitchComplete",
  energiepaspoortLegacy = "energiepaspoortLegacy",
  energiepaspoortSupplementary = "energiepaspoortSupplementary",
  energyLabelHouseTypeApartment = "energyLabelHouseTypeApartment",
  energyLabelHouseTypeDetached = "energyLabelHouseTypeDetached",
  energyLabelHouseTypeDuplex = "energyLabelHouseTypeDuplex",
  energyLabelHouseTypeRow = "energyLabelHouseTypeRow",
  energyLabelSupplementary = "energyLabelSupplementary",
  ennatuurlijk = "ennatuurlijk",
  financieelFitPlan = "financieelFitPlan",
  financieelFitSimplePlus = "financieelFitSimplePlus",
  financieelZekerCompleet = "financieelZekerCompleet",
  florius = "florius",
  floriusGreen = "floriusGreen",
  freeCampaign = "freeCampaign",
  fritsComplete = "fritsComplete",
  fullPlan = "fullPlan",
  gavoora = "gavoora",
  groeneHartComplete = "groeneHartComplete",
  habitata = "habitata",
  habitataLingewaard = "habitataLingewaard",
  homeInvest = "homeInvest",
  hpHypothekenSimplePlus = "hpHypothekenSimplePlus",
  hpHypothekenSimplePlusGreen = "hpHypothekenSimplePlusGreen",
  hypotheekVisieComplete = "hypotheekVisieComplete",
  impact = "impact",
  ingComplete = "ingComplete",
  installerPartnerSimple = "installerPartnerSimple",
  lotHypotheken = "lotHypotheken",
  mantgumComplete = "mantgumComplete",
  muntHypotheken = "muntHypotheken",
  nummerNulComplete = "nummerNulComplete",
  offerteAdviseurSimple = "offerteAdviseurSimple",
  offerteNL = "offerteNL",
  overstappenComplete = "overstappenComplete",
  promodomo = "promodomo",
  quotesDirect = "quotesDirect",
  quotesLead = "quotesLead",
  quotesOnly = "quotesOnly",
  republishSimple = "republishSimple",
  simpelSubsidie = "simpelSubsidie",
  simple = "simple",
  slimsterSimple = "slimsterSimple",
  slimsterSimpleFree = "slimsterSimpleFree",
  soldsupply = "soldsupply",
  solvariSimple = "solvariSimple",
  triodos = "triodos",
  triodosLabelDifference = "triodosLabelDifference",
  triodosLabelOnly = "triodosLabelOnly",
  triodosPlusLabel = "triodosPlusLabel",
  vanBruggenComplete = "vanBruggenComplete",
  vanLanschotKempenComplete = "vanLanschotKempenComplete",
  vasteLastenBond = "vasteLastenBond",
  vasteLastenBondPV = "vasteLastenBondPV",
  veldsinkComplete = "veldsinkComplete",
  veldsinkSimple = "veldsinkSimple",
  vennComplete = "vennComplete",
  verbruggeComplete = "verbruggeComplete",
  viisiSimple = "viisiSimple",
  viisiSimpleGreen = "viisiSimpleGreen",
  vistaComplete = "vistaComplete",
  vvaaComplete = "vvaaComplete",
  vvaaSimple = "vvaaSimple",
  yonegoSimple = "yonegoSimple",
}

export enum EnergyLabel {
  A = "A",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
}

export enum Feasibility {
  feasible = "feasible",
  feasibleWithCaveats = "feasibleWithCaveats",
  infeasible = "infeasible",
  installed = "installed",
  unavailable = "unavailable",
  unknown = "unknown",
}

export enum GlassType {
  double = "double",
  hr = "hr",
  hrPlus = "hrPlus",
  hrPlus2 = "hrPlus2",
  single = "single",
  triple = "triple",
  vacuum = "vacuum",
}

export enum HeatPumpType {
  airAir = "airAir",
  airWater = "airWater",
  brineWater = "brineWater",
  groundWaterWater = "groundWaterWater",
}

export enum HeatingSystemType {
  highTempConvector = "highTempConvector",
  highTempRadiator = "highTempRadiator",
  lowTempConvector = "lowTempConvector",
  lowTempRadiator = "lowTempRadiator",
  underfloorHeating = "underfloorHeating",
}

export enum IntakeMethod {
  physical = "physical",
  virtual = "virtual",
}

export enum JobType {
  INSTALLATION = "INSTALLATION",
  PTC = "PTC",
}

export enum NodeType {
  default = "default",
  ending = "ending",
  start = "start",
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  OPEN = "OPEN",
  PAID = "PAID",
  PAYMENT_CANCELLED = "PAYMENT_CANCELLED",
  PAYMENT_EXPIRED = "PAYMENT_EXPIRED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PENDING = "PENDING",
}

export enum OrganizationalLevel {
  Agent = "Agent",
  Company = "Company",
  Office = "Office",
  Organization = "Organization",
}

export enum PartnerAgentRole {
  Admin = "Admin",
  CompanyManager = "CompanyManager",
  Employee = "Employee",
  MultiOfficeManager = "MultiOfficeManager",
  OfficeManager = "OfficeManager",
  OrganizationManager = "OrganizationManager",
}

export enum PartnerCollectiveKey {
  dak = "dak",
  homeInvest = "homeInvest",
}

export enum PartnerMortgageLender {
  abn = "abn",
  aegon = "aegon",
  allianz = "allianz",
  argenta = "argenta",
  asn = "asn",
  asr = "asr",
  attens = "attens",
  bijBouwe = "bijBouwe",
  blg = "blg",
  centraalBeheer = "centraalBeheer",
  cmis = "cmis",
  deNederlandse = "deNederlandse",
  dynamic = "dynamic",
  florius = "florius",
  groeneHart = "groeneHart",
  handelsbanken = "handelsbanken",
  hollandWoont = "hollandWoont",
  hypotrust = "hypotrust",
  impact = "impact",
  ing = "ing",
  iqwoon = "iqwoon",
  lloyds = "lloyds",
  lot = "lot",
  merius = "merius",
  moneyou = "moneyou",
  munt = "munt",
  nationaleNederlanden = "nationaleNederlanden",
  nibc = "nibc",
  obvion = "obvion",
  onderlinge = "onderlinge",
  other = "other",
  philips = "philips",
  rabobank = "rabobank",
  regiobank = "regiobank",
  robuust = "robuust",
  sns = "sns",
  syntrus = "syntrus",
  tellius = "tellius",
  triodos = "triodos",
  tulp = "tulp",
  vanLanschot = "vanLanschot",
  venn = "venn",
  verder = "verder",
  vista = "vista",
  volksbank = "volksbank",
  woonNu = "woonNu",
  woonfonds = "woonfonds",
}

export enum PaymentMethod {
  creditcard = "creditcard",
  ideal = "ideal",
  paypal = "paypal",
}

export enum PlannerUserType {
  customer = "customer",
  installerAgent = "installerAgent",
  operator = "operator",
}

export enum PriceUnit {
  hour = "hour",
  m = "m",
  m2 = "m2",
  m3 = "m3",
  unit = "unit",
}

export enum ProductCategory {
  centralHeatingBoiler = "centralHeatingBoiler",
  chargingBox = "chargingBox",
  completePvSystem = "completePvSystem",
  floorInsulationMaterial = "floorInsulationMaterial",
  fusebox = "fusebox",
  glazing = "glazing",
  heatPump = "heatPump",
  homeBattery = "homeBattery",
  installationMaterial = "installationMaterial",
  inverter = "inverter",
  labor = "labor",
  miscellaneousMain = "miscellaneousMain",
  optimizer = "optimizer",
  other = "other",
  pvPanel = "pvPanel",
  roofFinish = "roofFinish",
  roofInsulationMaterial = "roofInsulationMaterial",
  sedum = "sedum",
  service = "service",
  thermostat = "thermostat",
  underfloorHeating = "underfloorHeating",
  wallInsulationMaterial = "wallInsulationMaterial",
  windowFrame = "windowFrame",
}

export enum PvPanelType {
  glassFoil = "glassFoil",
  glassGlass = "glassGlass",
  integrated = "integrated",
  roofTile = "roofTile",
}

export enum QuestionType {
  boolean = "boolean",
  checkbox = "checkbox",
  date = "date",
  number = "number",
  radio = "radio",
  range = "range",
  string = "string",
  textarea = "textarea",
}

export enum QuestioningGlassType {
  double = "double",
  hr = "hr",
  single = "single",
}

export enum QuoteCreationMethod {
  Installatron = "Installatron",
  QuoteBuilder = "QuoteBuilder",
}

export enum QuoteExperiment {
  d2cOperational = "d2cOperational",
  d2cSmokeScreen = "d2cSmokeScreen",
}

export enum QuotePackageGoal {
  Comfort = "Comfort",
  Emission = "Emission",
  EnergyLabel = "EnergyLabel",
  PaybackTime = "PaybackTime",
}

export enum Slurper {
  airConditioning = "airConditioning",
  electricCar = "electricCar",
  heatPump = "heatPump",
  none = "none",
  other = "other",
  swimmingPool = "swimmingPool",
  underfloorHeating = "underfloorHeating",
  waterBed = "waterBed",
}

export enum Solution {
  airToAirHeatPump = "airToAirHeatPump",
  atticFloorInsulation = "atticFloorInsulation",
  centralHeatingBoiler = "centralHeatingBoiler",
  chargingBox = "chargingBox",
  crawlspaceInsulation = "crawlspaceInsulation",
  electricHeatPump = "electricHeatPump",
  floorInsulation = "floorInsulation",
  homeBattery = "homeBattery",
  hybridHeatPump = "hybridHeatPump",
  innerRoofInsulation = "innerRoofInsulation",
  miscellaneous = "miscellaneous",
  pvSystem = "pvSystem",
  sedum = "sedum",
  underfloorHeating = "underfloorHeating",
  wallInsulation = "wallInsulation",
  windowFrames = "windowFrames",
  windowGlazing = "windowGlazing",
}

export enum SpaceType {
  living = "living",
  sleeping = "sleeping",
}

export enum SplitUnitType {
  monoblock = "monoblock",
  split = "split",
}

export enum TimeSlotPriority {
  chronological = "chronological",
  travelDistance = "travelDistance",
}

export enum UserType {
  customer = "customer",
  installerAgent = "installerAgent",
  lead = "lead",
  operator = "operator",
  partnerAgent = "partnerAgent",
}

export enum WallSurfaceType {
  ALREADY_INSULATED = "ALREADY_INSULATED",
  INSULATABLE = "INSULATABLE",
  NONE = "NONE",
  UNINSULATABLE = "UNINSULATABLE",
}

export interface AddressInput {
  readonly zip: string;
  readonly number: number;
  readonly suffix?: string | null;
  readonly coordinates?: CoordinatesInput | null;
  readonly city?: string | null;
  readonly street?: string | null;
  readonly municipality?: string | null;
}

export interface AdviserAppointmentSlotInput {
  readonly start: any;
  readonly end: any;
  readonly duration: DurationInput;
  readonly adviserId: string;
  readonly priority?: number | null;
}

export interface AnswerInputType {
  readonly boolean?: boolean | null;
  readonly date?: any | null;
  readonly float?: number | null;
  readonly string?: string | null;
  readonly stringArray?: ReadonlyArray<string> | null;
  readonly dateArray?: ReadonlyArray<any> | null;
  readonly floatArray?: ReadonlyArray<number> | null;
  readonly booleanArray?: ReadonlyArray<boolean> | null;
}

export interface AppliancesInput {
  readonly ledLighting?: boolean | null;
  readonly dishwasher?: boolean | null;
  readonly shower?: boolean | null;
  readonly savingShower?: boolean | null;
  readonly bathtub?: boolean | null;
  readonly kitchenBoiler?: boolean | null;
  readonly boilerType?: number | null;
}

export interface AppointmentInput {
  readonly date: any;
  readonly endDate?: any | null;
  readonly type?: string | null;
  readonly notes?: string | null;
}

export interface BasicAddressInput {
  readonly zip: string;
  readonly number: number;
  readonly suffix?: string | null;
}

export interface BusinessInput {
  readonly name?: string | null;
  readonly address?: AddressInput | null;
  readonly vat?: string | null;
  readonly coc?: number | null;
  readonly iban?: string | null;
}

export interface ChargingBoxInput {
  readonly fixedCable?: boolean | null;
  readonly distanceFuseBox?: number | null;
  readonly diggingRequired?: boolean | null;
  readonly fuseBoxNextToOutsideWall?: boolean | null;
  readonly attachToWall?: string | null;
  readonly cableRouteDescription?: string | null;
  readonly customerWillingToRepair?: boolean | null;
  readonly metersToBeDug?: number | null;
  readonly onOwnProperty?: boolean | null;
  readonly loadBalancing?: boolean | null;
  readonly businessExpensing?: boolean | null;
  readonly drillingAmount?: number | null;
}

export interface ConsentInput {
  readonly date: any;
  readonly medium: string;
  readonly title: string;
  readonly version: number;
  readonly withdrawn?: any | null;
}

export interface ConsumptionInput {
  readonly electricity?: number | null;
  readonly gas?: number | null;
}

export interface CoordinatesInput {
  readonly longitude?: number | null;
  readonly latitude?: number | null;
}

export interface CrawlspaceInput {
  readonly compartments?: number | null;
  readonly height?: number | null;
  readonly insulatableArea?: number | null;
  readonly ventilation?: boolean | null;
}

export interface CrawlspaceSituationInput {
  readonly compartmentsAccessible?: string | null;
  readonly hatchAccessible?: boolean | null;
  readonly hatchLocation?: ReadonlyArray<string> | null;
  readonly obstruction?: boolean | null;
  readonly obstructionClearableByCustomer?: boolean | null;
  readonly passagesRequired?: number | null;
  readonly pipework?: boolean | null;
  readonly rubbish?: boolean | null;
  readonly separator?: string | null;
  readonly wetness?: string | null;
  readonly ventilationGrillesRequired?: number | null;
  readonly solutionAdvised?: string | null;
  readonly insulationPerimeter?: number | null;
  readonly advisedInsulationThickness?: number | null;
  readonly hatchLength?: number | null;
  readonly hatchWidth?: number | null;
}

export interface CreatePartnerAgentInput {
  readonly gender?: string | null;
  readonly email: string;
  readonly password?: string | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly phone: string;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly shared?: boolean | null;
  readonly role?: PartnerAgentRole | null;
}

export interface CreateQuoteInput {
  readonly items: ReadonlyArray<InstallationItemInput>;
  readonly solution: Solution;
  readonly energyDelta: EnergyDeltaInput;
  readonly discount?: DiscountInput | null;
  readonly pvSystemInfo?: QuotePvSystemInfoInput | null;
  readonly final?: any | null;
  readonly files?: ReadonlyArray<any> | null;
  readonly text?: string | null;
  readonly isAdvised?: boolean | null;
  readonly experiments?: ReadonlyArray<QuoteExperiment> | null;
}

export interface CurrentInsulationInput {
  readonly locations?: ReadonlyArray<string> | null;
  readonly material?: ReadonlyArray<string> | null;
  readonly thickness?: number | null;
}

export interface CurrentSystemInput {
  readonly energyYield?: number | null;
  readonly energyYieldPercentage?: number | null;
  readonly panels?: number | null;
  readonly panelBrand?: string | null;
  readonly panelPower?: number | null;
  readonly panelType?: string | null;
  readonly inverterBrand?: string | null;
  readonly extensible?: string | null;
  readonly inverterPower?: number | null;
  readonly needsDisassembly?: boolean | null;
}

export interface CustomerDutyPackageRequestInput {
  readonly customerId: string;
  readonly dutyPackage: string;
  readonly appointment: AppointmentInput;
  readonly phone: string;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly voucherCode?: string | null;
}

export interface CustomerEmailInput {
  readonly id: string;
  readonly gender?: string | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phone?: string | null;
  readonly address?: BasicAddressInput | null;
}

export interface CustomerInput {
  readonly id?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly address?: AddressInput | null;
  readonly phone?: string | null;
  readonly email?: string | null;
  readonly motivation?: string | null;
  readonly utm?: UtmInput | null;
  readonly gender?: string | null;
  readonly needs?: CustomerNeedsInput | null;
  readonly password?: string | null;
  readonly landingPage?: string | null;
  readonly houses?: ReadonlyArray<(string | null)> | null;
  readonly business?: BusinessInput | null;
  readonly pipedriveId?: number | null;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly referrer?: string | null;
}

export interface CustomerNeedsInput {
  readonly budget?: number | null;
  readonly anticipatedSlurpers?: ReadonlyArray<(Slurper | null)> | null;
  readonly extraComfort?: boolean | null;
  readonly floor?: FloorNeedsInput | null;
  readonly heating?: HeatingNeedsInput | null;
  readonly windows?: WindowsNeedsInput | null;
  readonly solar?: SolarNeedsInput | null;
  readonly desiredLabel?: string | null;
  readonly expectedStay?: number | null;
  readonly motivations?: ReadonlyArray<(string | null)> | null;
  readonly cameFrom?: string | null;
}

export interface CustomerSatisfactionInput {
  readonly answer: string;
  readonly source: string;
  readonly form: string;
  readonly userId?: string | null;
}

export interface DateRangeInput {
  readonly start: any;
  readonly end: any;
}

export interface DiscountInput {
  readonly amount: number;
  readonly text: string;
  readonly type: DiscountType;
}

export interface DossierInput {
  readonly partnerAgentId: string;
  readonly customerFirstName?: string | null;
  readonly customerLastName?: string | null;
  readonly address: BasicAddressInput;
  readonly mortgageLender?: PartnerMortgageLender | null;
  readonly collective?: PartnerCollectiveKey | null;
  readonly houseId?: string | null;
  readonly hasGreenMortgage?: boolean | null;
}

export interface DossierUpdate {
  readonly customerFirstName: string;
  readonly customerLastName: string;
  readonly mortgageLender?: PartnerMortgageLender | null;
  readonly collective?: PartnerCollectiveKey | null;
  readonly hasGreenMortgage?: boolean | null;
}

export interface DurationInput {
  readonly length: number;
  readonly unit: DurationUnit;
}

export interface ElectricsSituationInput {
  readonly availableFuseGroups?: number | null;
  readonly availablePowerSocket?: boolean | null;
  readonly distributorRequired?: string | null;
  readonly hasMainFuse?: boolean | null;
  readonly isWashingMachineOnSeparateFuseGroup?: string | null;
  readonly modemAccessibleFromInverter?: string | null;
  readonly modemLocation?: string | null;
  readonly transformerCurrent?: number | null;
  readonly transformerInternetAccess?: boolean | null;
  readonly transformerPhases?: number | null;
  readonly storageCapacity?: number | null;
  readonly availableFuseBoxCapacity?: number | null;
  readonly requiredChangesFuseBox?: string | null;
  readonly hasRCD?: boolean | null;
}

export interface EnergyDeltaInput {
  readonly gasFactor?: number | null;
  readonly electricityConsumption?: number | null;
  readonly electricityProduction?: number | null;
  readonly energyIndex?: number | null;
}

export interface EnergyPriceSituationInput {
  readonly electricity?: number | null;
  readonly gas?: number | null;
  readonly gasNetworkOperator?: number | null;
}

export interface EnergyPricesInput {
  readonly gas?: number | null;
  readonly electricity?: number | null;
  readonly discountedElectricity?: number | null;
}

export interface FloorInput {
  readonly area?: number | null;
  readonly material?: string | null;
  readonly rc?: number | null;
  readonly rcBasedOnYearAndDecree?: boolean | null;
  readonly insulatableArea?: number | null;
  readonly nonInsulatableArea?: number | null;
  readonly insulatedArea?: number | null;
  readonly floorSystem?: string | null;
  readonly surfaces?: ReadonlyArray<(FloorSurfaceInput | null)> | null;
  readonly types?: ReadonlyArray<(string | null)> | null;
  readonly moisturePercentage?: number | null;
}

export interface FloorNeedsInput {
  readonly underfloorHeating?: boolean | null;
}

export interface FloorSituationInput {
  readonly annexInsulationRequired?: boolean | null;
  readonly currentInsulation?: CurrentInsulationInput | null;
  readonly finish?: ReadonlyArray<string> | null;
  readonly musty?: boolean | null;
  readonly reasonNotAdvised?: string | null;
  readonly permissionVVE?: boolean | null;
  readonly insulationLoosening?: boolean | null;
  readonly ventilationGrillesRequired?: number | null;
  readonly solutionAdvised?: string | null;
}

export interface FloorSurfaceInput {
  readonly name: string;
  readonly type?: string | null;
  readonly rc?: number | null;
  readonly geometry?: GeometryInput | null;
}

export interface FullAddressInput {
  readonly zip: string;
  readonly number: number;
  readonly suffix?: string | null;
  readonly street: string;
  readonly city: string;
  readonly province?: string | null;
  readonly municipality?: string | null;
}

export interface GeometryInput {
  readonly type?: string | null;
  readonly coordinates?: ReadonlyArray<(CoordinatesInput | null)> | null;
}

export interface HeatingInput {
  readonly type?: number | null;
  readonly boilerAge?: number | null;
  readonly heatEmissionSystems?: ReadonlyArray<(string | null)> | null;
  readonly installations?: ReadonlyArray<(string | null)> | null;
  readonly ventilationType?: number | null;
  readonly draught?: string | null;
  readonly pilotLight?: boolean | null;
  readonly heatPumpPipelineLength?: number | null;
  readonly floorHeatingGroups?: number | null;
  readonly floorHeatingSupplyTemperature?: number | null;
  readonly weatherStripping?: boolean | null;
}

export interface HeatingNeedsInput {
  readonly multiHotWater?: boolean | null;
  readonly newThermostat?: string | null;
  readonly boilerBrandPreference?: string | null;
  readonly extraWarmWaterCapacity?: boolean | null;
}

export interface HeatingSituationInput {
  readonly boilerBrand?: string | null;
  readonly boilerInstallationYear?: number | null;
  readonly currentBoilerLocation?: string | null;
  readonly heatPumpPipelineWorkRequired?: ReadonlyArray<string> | null;
  readonly scaffoldRequired?: boolean | null;
  readonly CW?: number | null;
  readonly flueGasOutlet?: string | null;
  readonly futureGasSlurpers?: ReadonlyArray<string> | null;
  readonly heatPumpBrand?: string | null;
  readonly newBoilerLocation?: string | null;
  readonly reasonNotAdvised?: string | null;
  readonly maintenanceContract?: boolean | null;
  readonly furtherInsulationPlanned?: boolean | null;
  readonly plannedInsulation?: ReadonlyArray<string> | null;
  readonly isCurrentThermostatSameBrand?: boolean | null;
  readonly availablePowerSocketForHeatPump?: boolean | null;
  readonly currentHeatingTemperature?: number | null;
  readonly boilerOrBufferTankAvailableWorkspace?: string | null;
  readonly heatPumpInsideUnitAvailableWorkspace?: string | null;
  readonly requiredItemsPlacingHeatPumpOutsideUnit?: string | null;
  readonly requiredItemsPlacingAirToAirHeatPumpOutsideUnit?: string | null;
  readonly heatPumpOutsideUnitDistanceToDrainage?: number | null;
  readonly pipeRouteHeatPumpOutsideToInsideUnit?: string | null;
  readonly pipeRouteAirToAirHeatPumpOutsideToInsideUnit?: string | null;
  readonly cableRouteHeatPumpOutsideToInsideUnit?: string | null;
  readonly isCookingWithGas?: boolean | null;
  readonly waterConsumption?: number | null;
  readonly expectedGasConsumptionAfterInsulation?: number | null;
  readonly suggestedHeatPumpBoilerLocation?: string | null;
  readonly bearingFloorMaterialAtBoiler?: string | null;
  readonly hasAsbestos?: boolean | null;
  readonly heatPumpOutsideUnitDistanceToFuseBox?: number | null;
  readonly airToAirHeatPumpOutsideUnitMountType?: string | null;
  readonly condensatePumpRequired?: string | null;
  readonly airToAirCustomerGoal?: string | null;
  readonly airToAirLocation?: string | null;
  readonly airToAirAmountOutsideUnits?: number | null;
  readonly airToAirPipeRouteLength?: number | null;
  readonly airToAirAmountRoomsWithInsideUnits?: number | null;
  readonly airToAirRoomSizes?: string | null;
  readonly underfloorHeatingArea?: number | null;
  readonly underfloorHeatingInstallationMethod?: string | null;
  readonly underfloorHeatingMillingFloorType?: ReadonlyArray<string> | null;
  readonly underfloorHeatingLevelingRequired?: string | null;
  readonly underfloorHeatingFinishRemoval?: string | null;
  readonly underfloorHeatingDistributorPowerSocket?: boolean | null;
  readonly underfloorHeatingMillingMachineUpstairs?: boolean | null;
}

export interface HomeBatterySituationInput {
  readonly advisedCapacity?: number | null;
  readonly cableRoute?: string | null;
  readonly cableRouteCableLength?: number | null;
  readonly spaceAround?: boolean | null;
  readonly needsFireResistantPlate?: boolean | null;
}

export interface InstallationItemInput {
  readonly productId: string;
  readonly amount: number;
  readonly retailPrice: number;
  readonly purchasePrice: number;
  readonly supplierId: string;
}

export interface InstallerNotesInput {
  readonly general?: string | null;
  readonly pvSystem?: string | null;
  readonly wallInsulation?: string | null;
  readonly floorInsulation?: string | null;
  readonly crawlspaceInsulation?: string | null;
  readonly innerRoofInsulation?: string | null;
  readonly atticFloorInsulation?: string | null;
  readonly windowGlazing?: string | null;
  readonly windowFrames?: string | null;
  readonly centralHeatingBoiler?: string | null;
  readonly hybridHeatPump?: string | null;
  readonly electricHeatPump?: string | null;
  readonly airToAirHeatPump?: string | null;
  readonly underfloorHeating?: string | null;
  readonly chargingBox?: string | null;
  readonly homeBattery?: string | null;
  readonly pvSystemCableRoute?: string | null;
}

export interface IntakeInput {
  readonly id?: string | null;
  readonly houseId?: string | null;
  readonly operatorId?: string | null;
  readonly appliances?: AppliancesInput | null;
  readonly area?: number | null;
  readonly conservatory?: boolean | null;
  readonly constructionYear?: number | null;
  readonly crawlspace?: CrawlspaceInput | null;
  readonly floor?: FloorInput | null;
  readonly hasAnnex?: boolean | null;
  readonly heating?: HeatingInput | null;
  readonly monument?: boolean | null;
  readonly notes?: NotesInput | null;
  readonly pvSystemPower?: number | null;
  readonly residents?: number | null;
  readonly roof?: RoofInput | null;
  readonly started?: any | null;
  readonly stories?: number | null;
  readonly type?: number | null;
  readonly typeId?: number | null;
  readonly vve?: boolean | null;
  readonly walls?: WallsInput | null;
  readonly windows?: WindowsInput | null;
  readonly woz?: number | null;
  readonly hasBasement?: boolean | null;
}

export interface JourneyEventInput {
  readonly houseId?: string | null;
  readonly utm?: UtmInput | null;
  readonly date?: any | null;
  readonly pageViews?: ReadonlyArray<string> | null;
  readonly referrer?: string | null;
  readonly entityId?: string | null;
  readonly entityType?: string | null;
}

export interface LeadDutyPackageRequestInput {
  readonly dutyPackage: string;
  readonly leadId: string;
  readonly gender: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly appointment: AppointmentInput;
  readonly phone: string;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly voucherCode?: string | null;
}

export interface LeadInput {
  readonly id?: string | null;
  readonly utm?: UtmInput | null;
  readonly landingPage?: string | null;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly motivation?: string | null;
  readonly referrer?: string | null;
}

export interface NotesInput {
  readonly general?: string | null;
  readonly pvSystem?: string | null;
  readonly walls?: string | null;
  readonly floor?: string | null;
  readonly roof?: string | null;
  readonly heating?: string | null;
  readonly insulationGlazing?: string | null;
}

export interface OmniformAnswerInput {
  readonly questionKey: string;
  readonly answer: AnswerInputType;
}

export interface OperatorDutyPackageRequestInput {
  readonly dutyPackage: string;
  readonly appointment: AppointmentInput;
  readonly phone: string;
  readonly extraDuties: ReadonlyArray<DutyName>;
  readonly isPaid?: boolean | null;
  readonly sendMail: boolean;
  readonly voucherCode?: string | null;
  readonly notes?: string | null;
}

export interface PartnerAgentRequestWithoutDossierInput {
  readonly address: BasicAddressInput;
  readonly loanId?: string | null;
  readonly hasGreenMortgage?: boolean | null;
  readonly partnerAgentId: string;
  readonly email: string;
  readonly gender: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly phone: string;
  readonly mortgageLender?: PartnerMortgageLender | null;
  readonly collective?: PartnerCollectiveKey | null;
  readonly appointment: AppointmentInput;
  readonly motivation?: string | null;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly dutyPackage: string;
  readonly extraDuties?: ReadonlyArray<DutyName> | null;
  readonly houseId?: string | null;
  readonly voucherCode?: string | null;
  readonly notes?: string | null;
}

export interface PlaceOrderCustomerInput {
  readonly id: string;
  readonly gender: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phone: string;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
}

export interface PreDossierInput {
  readonly partnerAgentId: string;
  readonly customerFirstName?: string | null;
  readonly customerLastName?: string | null;
  readonly mortgageLender?: PartnerMortgageLender | null;
  readonly collective?: PartnerCollectiveKey | null;
}

export interface QuotePvSystemInfoInput {
  readonly cardinalDirection?: string | null;
  readonly multipleRoofSurfaces?: boolean | null;
  readonly installationPlanProperties?: string | null;
  readonly neighbourDiscount?: boolean | null;
  readonly hasElectricityStorage?: boolean | null;
}

export interface RatingInput {
  readonly work?: number | null;
  readonly communication?: number | null;
  readonly extraWork?: number | null;
  readonly cleanliness?: number | null;
  readonly friendliness?: number | null;
  readonly professionalism?: number | null;
  readonly punctuality?: number | null;
  readonly serviceRating?: number | null;
  readonly improvements?: string | null;
  readonly comment?: string | null;
  readonly notesOnDEB?: string | null;
  readonly notesOnInstaller?: string | null;
}

export interface RoofInput {
  readonly area?: number | null;
  readonly azimuth?: number | null;
  readonly type?: string | null;
  readonly rc?: number | null;
  readonly roofing?: ReadonlyArray<(string | null)> | null;
  readonly shape?: ReadonlyArray<(string | null)> | null;
  readonly surfaces?: ReadonlyArray<(RoofSurfaceInput | null)> | null;
  readonly ridgeHeight?: number | null;
  readonly insulatableInnerRoofArea?: number | null;
  readonly insulatableAtticFloorArea?: number | null;
  readonly atticFloorInsulationDepth?: number | null;
  readonly insulatedArea?: number | null;
  readonly trussWidth?: number | null;
  readonly nonInsulatableArea?: number | null;
  readonly inclination?: number | null;
  readonly atticFloorArea?: number | null;
}

export interface RoofSituationInput {
  readonly advisedAtticFloorInsulationThickness?: number | null;
  readonly advisedInnerRoofInsulationProduct?: string | null;
  readonly amountKneeWall?: number | null;
  readonly amountPartitionWall?: number | null;
  readonly amountWindowsInTargetArea?: number | null;
  readonly atticIsLivingSpace?: boolean | null;
  readonly coverRequired?: ReadonlyArray<string> | null;
  readonly currentInsulation?: CurrentInsulationInput | null;
  readonly hasAsbestosInRoofing?: string | null;
  readonly outsideFinishState?: string | null;
  readonly permissionVVE?: boolean | null;
  readonly possibleInsulationLocations?: ReadonlyArray<string> | null;
  readonly purlinDistance?: number | null;
  readonly raisable?: boolean | null;
  readonly reasonNotAdvised?: string | null;
  readonly requiredInsulationMethod?: string | null;
  readonly sandwichPanelRequired?: string | null;
  readonly spareTiles?: boolean | null;
  readonly state?: string | null;
  readonly targetAreaAccess?: string | null;
  readonly targetAreaWorkable?: string | null;
  readonly tileLength?: number | null;
  readonly tileWidth?: number | null;
  readonly hasSurfaceObstacles?: boolean | null;
  readonly isCommonRafter?: boolean | null;
  readonly isVaporProof?: boolean | null;
  readonly maxWorkingHeight?: number | null;
  readonly amountRoomsToBeInsulated?: number | null;
  readonly hasFlooring?: boolean | null;
  readonly hasAtticFloorInsulation?: boolean | null;
  readonly currentAtticInsulationMaterial?: string | null;
  readonly atticFloorArea?: number | null;
  readonly purlinDepth?: number | null;
  readonly canMeasureTiles?: boolean | null;
}

export interface RoofSurfaceInput {
  readonly name: string;
  readonly type?: string | null;
  readonly rc?: number | null;
  readonly geometry?: GeometryInput | null;
  readonly tilt?: number | null;
}

export interface SedumInput {
  readonly surface?: string | null;
  readonly surfaceAngle?: number | null;
  readonly area?: number | null;
  readonly lossArea?: number | null;
  readonly roofingAge?: number | null;
  readonly eavesProfileAmount?: number | null;
  readonly carryingCapacity?: number | null;
  readonly hasGravel?: boolean | null;
  readonly roofHeight?: number | null;
  readonly accessibility?: string | null;
  readonly preferredType?: string | null;
  readonly gravelEdge?: boolean | null;
}

export interface SituationInput {
  readonly houseId?: string | null;
  readonly consumption?: ConsumptionInput | null;
  readonly consumptionAvailable?: string | null;
  readonly crawlspace?: CrawlspaceSituationInput | null;
  readonly currentOccupant?: boolean | null;
  readonly currentSlurpers?: ReadonlyArray<(Slurper | null)> | null;
  readonly electrics?: ElectricsSituationInput | null;
  readonly energyBill?: number | null;
  readonly parkingAvailable?: boolean | null;
  readonly energyPrices?: EnergyPriceSituationInput | null;
  readonly floor?: FloorSituationInput | null;
  readonly heating?: HeatingSituationInput | null;
  readonly installerNotes?: InstallerNotesInput | null;
  readonly roof?: RoofSituationInput | null;
  readonly slurperConsumption?: number | null;
  readonly solar?: SolarSituationInput | null;
  readonly walls?: WallSituationInput | null;
  readonly windows?: WindowsSituationInput | null;
  readonly chargingBox?: ChargingBoxInput | null;
  readonly homeBattery?: HomeBatterySituationInput | null;
  readonly sedum?: SedumInput | null;
}

export interface SolarNeedsInput {
  readonly pvPanelPreferences?: ReadonlyArray<string> | null;
  readonly pvRoofPreferences?: ReadonlyArray<string> | null;
}

export interface SolarSituationInput {
  readonly cableRoute?: string | null;
  readonly currentSystem?: CurrentSystemInput | null;
  readonly inverterLocation?: string | null;
  readonly permissionVVE?: boolean | null;
  readonly reasonNotAdvised?: string | null;
  readonly roofShading?: boolean | null;
  readonly accessibleThroughBack?: boolean | null;
  readonly amountUsableSurfaces?: number | null;
  readonly cableRouteCableLength?: number | null;
  readonly cableRouteNotes?: string | null;
  readonly hasAsbestosInRoofing?: string | null;
}

export interface UpdatePartnerAgentInput {
  readonly gender?: string | null;
  readonly email?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly phone?: string | null;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly shared?: boolean | null;
  readonly role?: PartnerAgentRole | null;
  readonly officeId?: string | null;
}

export interface UtmInput {
  readonly campaign?: string | null;
  readonly source?: string | null;
  readonly medium?: string | null;
  readonly term?: string | null;
  readonly content?: string | null;
}

export interface WallSituationInput {
  readonly amountCavityBrush?: number | null;
  readonly annexCoversOriginalWall?: boolean | null;
  readonly annexInsulationRequired?: boolean | null;
  readonly cavityWidth?: number | null;
  readonly currentInsulation?: CurrentInsulationInput | null;
  readonly drillDiameter?: number | null;
  readonly hasVentilationGrilles?: boolean | null;
  readonly hasWildlife?: boolean | null;
  readonly highestPointToBeInsulated?: number | null;
  readonly paint?: string | null;
  readonly permissionVVE?: boolean | null;
  readonly platformRequired?: string | null;
  readonly reasonNotAdvised?: string | null;
  readonly rubbish?: boolean | null;
  readonly sidesToBeInsulated?: ReadonlyArray<string> | null;
  readonly solutionAdvised?: string | null;
  readonly vegetation?: string | null;
  readonly ventilationGrillesRequired?: number | null;
  readonly wallsAccessible?: boolean | null;
  readonly seamState?: string | null;
  readonly moistureSpots?: boolean | null;
  readonly impregnableArea?: number | null;
  readonly wallImpregnationDesired?: boolean | null;
}

export interface WallSurfaceInput {
  readonly name: string;
  readonly type?: WallSurfaceType | null;
  readonly rc?: number | null;
  readonly cavityWidth?: number | null;
  readonly jointSize?: number | null;
  readonly gutterHeight?: number | null;
  readonly geometry?: GeometryInput | null;
}

export interface WallsInput {
  readonly amount?: number | null;
  readonly area?: number | null;
  readonly rc?: number | null;
  readonly insulatableArea?: number | null;
  readonly insulatedArea?: number | null;
  readonly gutterHeight?: number | null;
  readonly nonInsulatableArea?: number | null;
  readonly surfaces?: ReadonlyArray<(WallSurfaceInput | null)> | null;
  readonly connectedGarage?: boolean | null;
  readonly cavity?: boolean | null;
  readonly cavityWidth?: number | null;
}

export interface WindowsInput {
  readonly space?: SpaceType | null;
  readonly type?: GlassType | null;
  readonly totalSingleArea?: number | null;
  readonly totalDoubleArea?: number | null;
  readonly totalHRArea?: number | null;
}

export interface WindowsNeedsInput {
  readonly soundproofing?: boolean | null;
  readonly newFrames?: boolean | null;
}

export interface WindowsSituationInput {
  readonly amountRequiringReplacement?: number | null;
  readonly areaRequiringReplacementHr?: number | null;
  readonly areaRequiringReplacementSafety?: number | null;
  readonly amountRequiringReplacementHr?: number | null;
  readonly amountRequiringReplacementSafety?: number | null;
  readonly currentWindowFinish?: string | null;
  readonly floorsRequiringReplacement?: ReadonlyArray<number> | null;
  readonly frameCostExplained?: boolean | null;
  readonly hasOldGlazing?: boolean | null;
  readonly largestRequiringReplacement?: number | null;
  readonly leakyWindows?: number | null;
  readonly reasonNotAdvised?: string | null;
  readonly state?: string | null;
  readonly advisedAmountVentilationGrilles?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
