import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Container, Spinner } from '@energiebespaarders/symbols';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntercom } from 'react-use-intercom';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import { useClearSession } from '~/hooks/useClearSession';
import { useIsInitializing } from '~/hooks/useIsInitializing';
import { useMeOptional } from '~/hooks/useMe';
import usePageViewsTracker from '~/hooks/usePageViewsTracker';
import usePathname from '~/hooks/usePathname';
import { GET_ME, UPDATE_LEAD } from '~/queries/initialize';
import type { me as meQuery, me_me } from '~/types/generated/me';
import type { updateLead, updateLeadVariables } from '~/types/generated/updateLead';
import { inIFrame } from '~/utils/iFrameUtils';
import {
  getActiveHouseId,
  getIntercomUserData,
  identifyMe,
  initializeSessionData,
  initializeThirdPartyServices,
} from './initializeSession';
import { isUtmEmpty, parseUtm } from './utmUtils';
import type { ReactNode } from 'react';

export const AppInitializer = ({ children }: { children: ReactNode }) => {
  // Note: In NextJS, it's tempting to move all of this to server side, but...
  // Having it all on server side is not ideal either, keeping it on client-side is recommended (static optimization)
  const router = useRouter();
  const pathname = usePathname();
  const params = router.query as { preDossierId: string };
  const { isInitializing, setIsInitializing } = useIsInitializing();
  const { me, setMe } = useMeOptional();
  const { setActiveHouseId } = useActiveHouseId();
  const client = useApolloClient();
  const [cookies] = useCookies();
  const { clearSession } = useClearSession();
  const intercom = useIntercom();

  const needsReset = useMemo(() => {
    if (typeof window === 'undefined') return false;
    const isPreDossierSession = pathname === '/dossierverzoek' && !!params.preDossierId;
    const isHouselessIFrame = inIFrame() && !router.query.houseId;
    return isHouselessIFrame || isPreDossierSession;
  }, [params.preDossierId, pathname, router.query.houseId]);

  const [hasBeenReset, setHasBeenReset] = useState(false);
  const [showCacheMessage] = useState(false);

  const [updateLeadMutation] = useMutation<updateLead, updateLeadVariables>(UPDATE_LEAD);

  useEffect(() => {
    if (!router.isReady) return;
    if (typeof window === 'undefined') return;

    const { urlUtm } = parseUtm(router.query);

    if (!isUtmEmpty(urlUtm)) window.localStorage.setItem('urlUtm', JSON.stringify(urlUtm));
    if (document.referrer) window.localStorage.setItem('referrer', document.referrer);
  }, [router.isReady]); // eslint-disable-line

  // Keep track of previous user ID so we can tell when it's changed
  const prevMe = useRef(me);
  useEffect(() => {
    prevMe.current = me;
  }, [me]);

  const initializeMe = useCallback(
    async (me: me_me) => {
      if (!isInitializing) return;
      if (me.__typename === 'InstallerAgent') return;

      identifyMe(me);

      setMe(me);

      if (
        me.__typename === 'Lead' ||
        me.__typename === 'Customer' ||
        me.__typename === 'PartnerAgent'
      ) {
        const attrs = getIntercomUserData(me);
        const houseId = getActiveHouseId(me);

        // Overwrite houseId if given, default uses first house in `me.houses` array
        if (houseId) attrs.customAttributes = { ...attrs.customAttributes, houseId };

        intercom.update(attrs);

        if (me.__typename !== 'PartnerAgent') {
          const initialHouseId = getActiveHouseId(me);
          setActiveHouseId(initialHouseId);
          await initializeSessionData(me, updateLeadMutation, setMe, router);
        }
      }

      // If user has accepted cookies in this session, there will be a user-has-accepted-cookies cookie
      // For when it was in a previous session, we check me.consent
      if (
        (cookies['user-has-accepted-cookies'] ||
          ('consent' in me && me.consent?.some(c => c.title === 'cookies'))) &&
        me.__typename !== 'Operator'
      ) {
        initializeThirdPartyServices(me, cookies, pathname);
      }

      setIsInitializing(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cookies, router, setActiveHouseId, setIsInitializing, setMe, updateLeadMutation],
  );

  // When the user changes routes, check whether 3rd party services need to be (re)-initialized
  // (since we don't track LogRocket on certain pages like /blog)
  useEffect(() => {
    if (me && me.__typename !== 'Operator' && cookies['user-has-accepted-cookies']) {
      initializeThirdPartyServices(me, cookies, pathname);
    }
  }, [cookies, me, pathname]);

  // When me is retrieved through server-side props, it's already there on mount, and the rest should be initialized too
  useEffect(() => {
    if (me) void initializeMe(me);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For content pages, you can still view content when me can't be initialized
  useQuery<meQuery>(GET_ME, {
    fetchPolicy: 'network-only',
    skip: Boolean(me?.id),
    onCompleted: data => void initializeMe(data.me),
  });

  // Needed to trigger initialization when me is already set
  useEffect(() => {
    if (isInitializing && me && me.id !== prevMe.current?.id) void initializeMe(me);
  }, [initializeMe, isInitializing, me]);

  // NOTE: Using a lazy query instead of a refetch on the initial GET_ME query, since that only reruns `onCompleted` when `notifyOnNetworkStatusChange` is set to true – but that forces a rerender. This does not.
  const [lazyFetchMe] = useLazyQuery<meQuery>(GET_ME, {
    fetchPolicy: 'network-only',
    onCompleted: data => initializeMe(data.me),
  });

  // This effect resets the session by creating a new lead when necessary
  useEffect(() => {
    // If you're a lead with at least 1 house, create a new lead when reset is needed
    if (me && needsReset && !hasBeenReset) {
      if (me.__typename === 'Lead' && me.houses.length > 0) {
        const { urlUtm } = parseUtm(router.query);
        setIsInitializing(true);
        void clearSession('/', me.__typename === 'Lead' ? me.utm : urlUtm, lazyFetchMe);
      } else {
        void lazyFetchMe();
      }
      setHasBeenReset(true);
    }
  }, [
    me,
    lazyFetchMe,
    hasBeenReset,
    needsReset,
    clearSession,
    setActiveHouseId,
    setIsInitializing,
    client,
    router.query,
  ]);

  // TODO: could make this lazily loaded
  const CacheMessage = () => (
    <div>
      <h3>Hmm... hier lijkt iets niet goed te gaan</h3>
      <p>
        Als de pagina blijft laden, ligt het waarschijnlijk aan de cache van je browser. <br />
        Je browser vernieuwt dit meestal zelf, maar is soms niet helemaal op de hoogte van de
        laatste aanpassingen aan de website.
      </p>
      <p>
        <a
          href="https://www.refreshyourcache.com/nl/home/"
          title="Bekijk hoe je jouw browser cache kunt verversen"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bekijk hier hoe je jouw browser cache kunt verversen
        </a>
        ,
        <br />
        zodat je weer optimaal gebruik kunt maken van onze website.
      </p>
    </div>
  );

  return !needsReset || hasBeenReset ? (
    <>{children}</>
  ) : (
    <Container mx="auto" textAlign="center">
      <Spinner color={showCacheMessage ? 'orange' : 'green'} />
      {showCacheMessage && <CacheMessage />}
    </Container>
  );
};

/** In an iFrame, we don't to initialize leads, so we can skip the whole initalization logic */
const IFrameChecker: React.FC = ({ children }) => {
  const { setIsInitializing } = useIsInitializing();

  useEffect(() => {
    if (inIFrame()) setIsInitializing(false);
  }, [setIsInitializing]);

  // Page tracking also needs to happen in iframes
  // so putting it here instead of the AppInitializer itself
  // This will store all page views to local storage and send it to GA/GTM
  usePageViewsTracker();

  return inIFrame() ? <>{children}</> : <AppInitializer>{children}</AppInitializer>;
};

export default IFrameChecker;
