import Script from 'next/script';
import React, { useEffect } from 'react';
import { pushToDataLayer } from '~/utils/analytics';
import config from 'src/config';
import { containerEnvSnippet } from './gtmEnvironment';
import type { UTM } from './utmUtils';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

/** Should be called when user consents through the consent banner.
 * This is already set to granted when previously granted during initializion in the script below.
 */
export function updateGTMConsent() {
  // TODO: user should be able to have more granular control over these
  gtag('consent', 'update', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted',
  });
}

const GoogleTagManager: React.FC = () => {
  useEffect(() => {
    // Push UTM data to GTM
    const utm: UTM = window.localStorage.getItem('urlUtm')
      ? JSON.parse(window.localStorage.getItem('urlUtm') || '{}')
      : {};

    pushToDataLayer({
      ...(utm.source ? { source: utm.source } : {}),
      ...(utm.medium ? { medium: utm.medium } : {}),
      ...(utm.campaign ? { campaign: utm.campaign } : {}),
      ...(utm.term ? { term: utm.term } : {}),
      ...(utm.content ? { content: utm.content } : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Don't initialize GTM when running E2E tests
  // NOTE: We could choose to send this boolean to GTM dataLayer and determine from there which tags to fire or not, but that's a bit out of scope for now.
  if (navigator.webdriver) return null;

  return (
    <>
      <Script id="gtm-consent-initialization" strategy="afterInteractive">
        {/* Needs to be initialized before GTM itself */}
        {/* https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced */}
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        const consent = document.cookie.includes('user-has-accepted-cookies') ? 'granted' : 'denied';
        gtag('consent', 'default', {
          ad_storage: consent,
          ad_user_data: consent,
          ad_personalization: consent,
          analytics_storage: consent,
          personalization_storage: consent,
          functionality_storage: 'granted',
          security_storage: 'granted',
        });
      `}
      </Script>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${containerEnvSnippet}';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${config.keys.google.tagManager}')`}
      </Script>
    </>
  );
};

export default GoogleTagManager;
