import Head from 'next/head';
import usePathname from '~/hooks/usePathname';
import config from 'src/config';
import MetaTagLoader from './MetaTagLoader';
import globalStructuredData from './globalStructuredData';

const titleSuffix = ' • Duurzaam wonen zonder gedoe.';
const title = `Energiebespaarders${titleSuffix}`;

// NOTE: There are some conditional metatags, such as robots and favicon. These are still being set here. All others should be moved to DatoCMS or the Meta-Tags.json file that can be adjusted by MKT.
const AppHelmet = () => {
  const pathname = usePathname();
  const inEnergyPassport = pathname === '/mijn-energiepaspoort';
  return (
    <>
      <Head>
        <title key="title">{title}</title>
        <meta
          name="robots"
          key="robots"
          content={config.isProduction ? 'all' : 'noindex, nofollow'}
        />

        <link
          rel="shortcut icon"
          key="link-shortcut icon"
          href={inEnergyPassport ? '/app/img/epFavicon.png' : '/app/img/favicon.png'}
        />

        <link
          key="link-apple-touch-icon-72x72"
          rel="apple-touch-icon"
          sizes="72x72"
          href="/app/img/72.png"
        />
        <link
          key="link-apple-touch-icon-114x114"
          rel="apple-touch-icon"
          sizes="114x114"
          href="/app/img/114.png"
        />
        <link
          key="link-apple-touch-icon-144x144"
          rel="apple-touch-icon"
          sizes="144x144"
          href="/app/img/144.png"
        />
        <link
          key="link-apple-touch-icon-180x180"
          rel="apple-touch-icon"
          sizes="180x180"
          href="/app/img/180.png"
        />
        <link
          key="link-apple-touch-icon-196x196"
          rel="apple-touch-icon"
          sizes="196x196"
          href="/app/img/196.png"
        />
      </Head>
      <MetaTagLoader titleSuffix=" • Energiebespaarders" />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(globalStructuredData) }}
      />
    </>
  );
};

export default AppHelmet;
