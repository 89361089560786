import { Solution } from '@energiebespaarders/constants';
import type { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import type { Icon } from '@phosphor-icons/react';
import {
  ApproximateEquals,
  BatteryChargingVertical,
  Cards,
  ChargingStation,
  Circuitry,
  Fan,
  Flame,
  HouseLine,
  Leaf,
  Lightning,
  SolarPanel,
  StackSimple,
  Wall,
} from '@phosphor-icons/react';
import { ContactCategory, ContactSubject } from '~/types/graphql-global-types';
import roofImg from '/public/img/illustrations/cutouts/dakisolatie.jpg';
import glazingImg from '/public/img/illustrations/cutouts/isolatieglas.jpg';
import chargingBoxImg from '/public/img/illustrations/cutouts/laadpaal.png';
import wallImg from '/public/img/illustrations/cutouts/spouwmuurisolatie.jpg';
import boilerImg from '/public/img/illustrations/cutouts/verwarming.jpg';
import floorImg from '/public/img/illustrations/cutouts/vloerisolatie.jpg';
import pvImg from '/public/img/illustrations/cutouts/zonnepanelen.jpg';

/** Used on server side, when window/document is inaccessible */
export const FALLBACK_WINDOW_HEIGHT = 800;

export const termsAndConditionsPdfs: Record<string, string> = {
  '2023-11':
    'https://storage.googleapis.com/deb-documents/Algemene%20Voorwaarden%20Consumenten%20-%20De%20Energiebespaarders%2011-2023.pdf',
  '2023-03':
    'https://storage.googleapis.com/deb-documents/Algemene%20Voorwaarden%20Consumenten%20-%20De%20Energiebespaarders%2003-2023.pdf',
  '2022-12':
    'https://storage.googleapis.com/deb-documents/Algemene%20Voorwaarden%20Consumenten%20-%20De%20Energiebespaarders%2012-2022.pdf',
  '2022-05':
    'https://storage.googleapis.com/deb-documents/Algemene%20Voorwaarden%20Consumenten%20-%20De%20Energiebespaarders%2005-2022.pdf',
};

export const SOLUTION_ILLUSTRATIONS: Record<Solution, string> = {
  pvSystem: pvImg,
  wallInsulation: wallImg,
  crawlspaceInsulation: floorImg,
  floorInsulation: floorImg,
  innerRoofInsulation: roofImg,
  windowGlazing: glazingImg,
  hybridHeatPump: boilerImg,
  electricHeatPump: boilerImg,
  airToAirHeatPump: boilerImg,
  centralHeatingBoiler: boilerImg,
  atticFloorInsulation: roofImg,
  windowFrames: glazingImg,
  miscellaneous: '',
  sedum: '',
  chargingBox: chargingBoxImg,
  underfloorHeating: '',
  homeBattery: '',
};

export const SOLUTION_ICONS: Record<Solution, Icon> = {
  [Solution.WallInsulation]: Wall,
  [Solution.FloorInsulation]: StackSimple,
  [Solution.CrawlspaceInsulation]: StackSimple,
  [Solution.InnerRoofInsulation]: HouseLine,
  [Solution.AtticFloorInsulation]: StackSimple,
  [Solution.WindowGlazing]: Cards,
  [Solution.WindowFrames]: Cards,
  [Solution.PvSystem]: SolarPanel,
  [Solution.HybridHeatPump]: ApproximateEquals,
  [Solution.ElectricHeatPump]: Lightning,
  [Solution.AirToAirHeatPump]: Fan,
  [Solution.CentralHeatingBoiler]: Flame,
  [Solution.Miscellaneous]: Leaf,
  [Solution.Sedum]: Leaf,
  [Solution.ChargingBox]: ChargingStation,
  [Solution.UnderfloorHeating]: Circuitry,
  [Solution.HomeBattery]: BatteryChargingVertical,
};

export const STATUSES_NL: { [x: string]: string } = {
  sent: 'Verstuurd',
  accepted: 'Akkoord',
  cancelled: 'Geannuleerd',
  paid: 'Betaald',
  opened: 'Geopend',
  all: 'Alles',
};

export enum PartnerKey {
  ABN = 'abn',
  ASR = 'asr',
  AEGON = 'aegon',
  BudgetThuis = 'budgetThuis',
  Consumentenbond = 'consumentenbond',
  DAK = 'dak',
  EasySwitch = 'easySwitch',
  EBP = 'ebp',
  Ennatuurlijk = 'ennatuurlijk',
  FinancieelFit = 'financieelFit',
  FinancieelZeker = 'financieelZeker',
  Finzie = 'finzie',
  Florius = 'florius',
  Frits = 'frits',
  GavoorA = 'gavoora',
  GroeneHart = 'groeneHart',
  HpHypotheken = 'hpHypotheken',
  HuisHypotheek = 'huisHypotheek',
  Hypokeur = 'hypokeur',
  Hypotheek24 = 'hypotheek24',
  HypotheekBerekenen = 'hypotheekberekenen',
  Hypotheekshop = 'hypotheekshop',
  HypotheekVisie = 'hypotheekVisie',
  ImpactHypotheken = 'impactHypotheken',
  Lot = 'lot',
  Mangotree = 'mangotree',
  MUNT = 'munt',
  NBG = 'nbg',
  NIBC = 'nibc',
  Overstappen = 'overstappen',
  Promodomo = 'promodomo',
  Pwc = 'pwc',
  Syntrus = 'syntrus',
  Triodos = 'triodos',
  VanBruggen = 'vanbruggen',
  VanLanschot = 'vanlanschot',
  VanLanschotKempen = 'vanlanschotkempen',
  Veldsink = 'veldsink',
  Venn = 'venn',
  Verbrugge = 'verbrugge',
  Verder = 'verder',
  Viisi = 'viisi',
  Vista = 'vista',
}

export type PartnerRoute = { path: string; partner?: PartnerKey };

// ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
// IF YOU EDIT THIS MAKE SURE THAT YOU UPDATE THE ROUTES CONFIG IN routes.js (at the top)
// ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
/** The homepage-lookalike pages */
export const partnerLandingRoutes: PartnerRoute[] = [
  { path: '/florius', partner: PartnerKey.Florius },
  { path: '/frits', partner: PartnerKey.Frits },
  { path: '/gavoora', partner: PartnerKey.GavoorA },
  { path: '/huis-hypotheek', partner: PartnerKey.HuisHypotheek },
  { path: '/hypokeur', partner: PartnerKey.Hypokeur },
  { path: '/hypotheekberekenen', partner: PartnerKey.HypotheekBerekenen },
  { path: '/hypotheekshop', partner: PartnerKey.Hypotheekshop },
  { path: '/hypotheekvisie', partner: PartnerKey.HypotheekVisie },
  { path: '/munthypotheken', partner: PartnerKey.MUNT },
  { path: '/nbg', partner: PartnerKey.NBG },
  { path: '/overstappen', partner: PartnerKey.Overstappen },
  { path: '/promodomo', partner: PartnerKey.Promodomo },
  { path: '/triodos', partner: PartnerKey.Triodos },
  { path: '/vanlanschotkempen', partner: PartnerKey.VanLanschotKempen },
  { path: '/veldsink', partner: PartnerKey.Veldsink },
  { path: '/vennhypotheken', partner: PartnerKey.Venn },
  { path: '/verbrugge', partner: PartnerKey.Verbrugge },
  { path: '/viisi', partner: PartnerKey.Viisi },
  { path: '/vistahypotheken', partner: PartnerKey.Vista },
];

export const categoryOptions: DropdownOption<ContactCategory>[] = [
  {
    label: 'Ik heb een vraag',
    value: ContactCategory.question,
  },
  {
    label: 'Ik heb feedback',
    value: ContactCategory.feedback,
  },
  {
    label: 'Ik heb een technisch probleem',
    value: ContactCategory.technicalIssue,
  },
  {
    label: 'Ik heb een klacht',
    value: ContactCategory.complaint,
  },
  {
    label: 'Ik wil samenwerken met Energiebespaarders',
    value: ContactCategory.partnership,
  },
];

export const contactSubjects: Record<ContactCategory, DropdownOption<ContactSubject>[]> = {
  question: [
    {
      label: 'Over onze dienstverlening',
      value: ContactSubject.generalServiceQuestion,
    },
    {
      label: 'Over mijn advies of offertes',
      value: ContactSubject.adviceQuestion,
    },
    {
      label: 'Over mijn installatieplanning',
      value: ContactSubject.planningQuestion,
    },
    {
      label: 'Over het energielabel',
      value: ContactSubject.energyLabelQuestion,
    },
    {
      label: 'Over subsidies',
      value: ContactSubject.subsidyQuestion,
    },
    {
      label: 'Anders',
      value: ContactSubject.otherQuestion,
    },
  ],
  technicalIssue: [
    {
      label: 'Met mijn account',
      value: ContactSubject.accountIssue,
    },
    {
      label: 'Met e-mails en berichtgeving',
      value: ContactSubject.emailIssue,
    },
    {
      label: 'Anders',
      value: ContactSubject.otherIssue,
    },
  ],
  feedback: [
    {
      label: 'Over onze website',
      value: ContactSubject.websiteFeedback,
    },
    {
      label: 'Over onze dienstverlening',
      value: ContactSubject.serviceFeedback,
    },
    {
      label: 'Over een product of installatie',
      value: ContactSubject.installationFeedback,
    },
    {
      label: 'Anders',
      value: ContactSubject.otherFeedback,
    },
  ],
  complaint: [
    {
      label: 'Algemeen',
      value: ContactSubject.generalComplaint,
    },
    {
      label: 'Over ons advies en ontzorging',
      value: ContactSubject.serviceComplaint,
    },
    {
      label: 'De uitvoer of installateur',
      value: ContactSubject.installerComplaint,
    },
  ],
  partnership: [
    {
      label: 'Als installatiepartner',
      value: ContactSubject.installerPartner,
    },
    { label: 'Als financieel adviseur of geldverstrekker', value: ContactSubject.financialPartner },
    {
      label: 'Anders',
      value: ContactSubject.otherPartner,
    },
  ],
};

/**
 * timestamped: vrijdag 6 januari 2023 om 15:22
 * full: vrijdag 6 januari 2023
 * medium: vr 6 jan 2023
 * short: 06-01-2023
 * shortest: 6-1-23
 */
export const dateStringFormatters = {
  timestamped: 'dddd D MMMM YYYY [om] HH:mm',
  yearless: 'dddd D MMMM',
  full: 'dddd D MMMM YYYY',
  medium: 'D MMMM YYYY',
  short: 'DD-MM-YYYY',
  shortest: 'd-M-YY',
} as const;

const baseImgixParams = '?auto=format,compress&fit=crop&crop=faces';

export const customerTestimonialImages = {
  flohilProfile: `https://www.datocms-assets.com/50550/1672931469-flohil-22678a0e7f9b3ba01bef2d23fa2598e5.jpg${baseImgixParams}&w=300`,
  lans1: `https://www.datocms-assets.com/50550/1731342764-lans_1.png${baseImgixParams}&w=800`,
  lansBg: `https://www.datocms-assets.com/50550/1731342764-lans.jpg${baseImgixParams}&w=800`,
  lansProfile: `https://www.datocms-assets.com/50550/1731342764-lansprofile.jpg${baseImgixParams}&w=300`,
  nouwensProfile: `https://www.datocms-assets.com/50550/1731342764-nouwens.jpg${baseImgixParams}&w=300`,
  schut1: `https://www.datocms-assets.com/50550/1731342764-schut_1.jpg${baseImgixParams}&w=800`,
  schut2: `https://www.datocms-assets.com/50550/1731342764-schut_2.jpg${baseImgixParams}&w=800`,
  schutBg: `https://www.datocms-assets.com/50550/1731342764-bg_schut.jpg${baseImgixParams}&w=800`,
  schutProfile: `https://www.datocms-assets.com/50550/1674490266-schut-b24bc9f25aaa4dda2ab70dd7b27ba32e.jpg${baseImgixParams}&w=300`,
  soetekouw1: `https://www.datocms-assets.com/50550/1731342764-soetekouw_1.jpg${baseImgixParams}&w=800`,
  soetekouwBg: `https://www.datocms-assets.com/50550/1731342764-bg_soetekouw.jpg${baseImgixParams}&w=800`,
  soetekouwProfile: `https://www.datocms-assets.com/50550/1674227375-soetekouw-0e483611366deac90761195707ff1229.jpg${baseImgixParams}&w=300`,
  suzannejaco1: `https://www.datocms-assets.com/50550/1731342764-suzannejaco_1.jpg${baseImgixParams}&w=800`,
  suzannejacoBg: `https://www.datocms-assets.com/50550/1731342764-bg_suzannejaco.jpg${baseImgixParams}&w=800`,
  suzannejacoProfile: `https://www.datocms-assets.com/50550/1682498405-suzanne-en-jaco.jpg${baseImgixParams}&w=300`,
  vandenboogaard1: `https://www.datocms-assets.com/50550/1731342764-vandenboogaard_1.jpg${baseImgixParams}&w=800`,
  vandenboogaardBg: `https://www.datocms-assets.com/50550/1731342764-bg_vandenboogaard.jpg${baseImgixParams}&w=800`,
  vandenboogaardProfile: `https://www.datocms-assets.com/50550/1676401413-vandenboogaard-3fc832ed38439253618e384e84adad9a.jpg${baseImgixParams}&w=300`,
  vandenbroek1: `https://www.datocms-assets.com/50550/1731342764-vandenbroek_1.jpg${baseImgixParams}&w=800`,
  vandenbroekBg: `https://www.datocms-assets.com/50550/1731342764-bg_vandenbroek.jpg${baseImgixParams}&w=800`,
  vandenbroekProfile: `https://www.datocms-assets.com/50550/1672931546-vdbroek.jpg${baseImgixParams}&w=300`,
  vanderschans1: `https://www.datocms-assets.com/50550/1731342765-vanderschans.jpg${baseImgixParams}&w=800`,
  vanderschansBg: `https://www.datocms-assets.com/50550/1731342764-bg_vanderschans-min.jpg${baseImgixParams}&w=800`,
  vanderschansProfile: `https://www.datocms-assets.com/50550/1682498630-antonie-van-der-schans.jpg${baseImgixParams}&w=300`,
  vaneijk1: `https://www.datocms-assets.com/50550/1731342765-vaneijk_1.jpg${baseImgixParams}&w=800`,
  vaneijkBg: `https://www.datocms-assets.com/50550/1731342765-bg_vaneijk.jpg${baseImgixParams}&w=800`,
  vaneijkProfile: `https://www.datocms-assets.com/50550/1674227514-vaneijk-a8086330d050da0f6d5d861335c7e05a.jpg${baseImgixParams}&w=300`,
  vanveldhoven1: `https://www.datocms-assets.com/50550/1731342764-vanveldhoven1.jpg${baseImgixParams}&w=800`,
  vanveldhoven2: `https://www.datocms-assets.com/50550/1731342764-vanveldhoven2.jpg${baseImgixParams}&w=800`,
  vanveldhovenBg: `https://www.datocms-assets.com/50550/1731342764-bg_vanveldhoven.jpg${baseImgixParams}&w=800`,
  vanveldhovenProfile: `https://www.datocms-assets.com/50550/1672931587-thomas.jpg${baseImgixParams}&w=300`,
  wernernicolau1: `https://www.datocms-assets.com/50550/1731342764-wernernicolau_1.jpg${baseImgixParams}&w=800`,
  wernernicolau2: `https://www.datocms-assets.com/50550/1731342764-wernernicolau_2.jpg${baseImgixParams}&w=800`,
  wernernicolauBg: `https://www.datocms-assets.com/50550/1731342764-bg_wernernicolau.jpg${baseImgixParams}&w=800`,
  wernernicolauProfile: `https://www.datocms-assets.com/50550/1682498447-walter-patricia.jpg${baseImgixParams}&w=300`,
};

export const marketingImages = {
  wallInsulationLadders: `https://www.datocms-assets.com/50550/1731402822-wallinsulation1-min.jpg${baseImgixParams}&w=800`,
  wallInsulationPortrait: `https://www.datocms-assets.com/50550/1731402823-wallinsulation2-min.jpg${baseImgixParams}&w=800`,

  markGesturing: `https://www.datocms-assets.com/50550/1731402823-markgesturing.webp${baseImgixParams}&w=800`,
  markCar: `https://www.datocms-assets.com/50550/1684227158-energiebespaarders_img_0670_wardie.jpg${baseImgixParams}&w=800`,
  markMeasuringGlass: `https://www.datocms-assets.com/50550/1731402830-markmeasuring.webp${baseImgixParams}&w=800`,
  markEndoscope: `https://www.datocms-assets.com/50550/1731402822-markendoscope.webp${baseImgixParams}&w=800`,
  markRoofWindow: `https://www.datocms-assets.com/50550/1684227176-energiebespaarders_img_0571_wardie.jpg${baseImgixParams}&w=800`,
  markTableSide: `https://www.datocms-assets.com/50550/1684226920-energiebespaarders_img_0398_wardie.jpg${baseImgixParams}&w=800`,
  markTableFront: `https://www.datocms-assets.com/50550/1684226981-energiebespaarders_img_0387_wardie.jpg${baseImgixParams}&w=800`,
  markPhone: `https://www.datocms-assets.com/50550/1684226985-energiebespaarders_img_0446_wardie.jpg${baseImgixParams}&w=800`,
  laptopCloseup: `https://www.datocms-assets.com/50550/1731402829-laptop.webp${baseImgixParams}&w=800`,

  officeOverview: `https://www.datocms-assets.com/50550/1727787365-michelle_piergoelam-energiebespaarders-97.jpg${baseImgixParams}&w=800`,

  pieterLaptop: `https://www.datocms-assets.com/50550/1660214877-deb-marketing-pieter-2286.jpg${baseImgixParams}&w=800`,
  pieterLaptopBack: `https://www.datocms-assets.com/50550/1660205844-deb-marketing-pieter-2306.jpg${baseImgixParams}&w=800`,

  installerBus: `https://www.datocms-assets.com/50550/1731402822-bus.jpg${baseImgixParams}&w=800`,
  liftingPv: `https://www.datocms-assets.com/50550/1731402823-liftingpvpanel.jpg${baseImgixParams}&w=800`,
};

export const solutionLeadValue: Record<Solution, number> = {
  [Solution.WallInsulation]: 20,
  [Solution.FloorInsulation]: 20,
  [Solution.CrawlspaceInsulation]: 20,
  [Solution.InnerRoofInsulation]: 20,
  [Solution.AtticFloorInsulation]: 20,
  [Solution.WindowGlazing]: 30,
  [Solution.PvSystem]: 30,
  [Solution.HybridHeatPump]: 30,
  [Solution.ElectricHeatPump]: 30,
  [Solution.AirToAirHeatPump]: 20,
  [Solution.CentralHeatingBoiler]: 20,
  [Solution.HomeBattery]: 15,
  [Solution.WindowFrames]: 0,
  [Solution.Miscellaneous]: 0,
  [Solution.Sedum]: 0,
  [Solution.ChargingBox]: 0,
  [Solution.UnderfloorHeating]: 0,
};

export const HEAT_EMISSION_SYSTEMS_NL: Record<string, string> = {
  radiators: 'radiatoren',
  convection: 'convectoren',
  floorDucts: 'convectorputten',
  waterBasedUnderfloorHeating: 'watergedragen vloerverwarming',
  electricUnderfloorHeating: 'elektrishe vloerverwarming',
  wallHeating: 'wandverwarming',
  infraredPanels: 'infraroodpanelen',
  underfloorHeating: 'vloerverwarming',
  hotAirHeating: 'heteluchtverwarming',
};


/** Filtered out for otherwise (near-)duplicate estimates – and/or solutions we don't actually have any partners for atm */
export const ignoredSolutions: Solution[] = [
  Solution.CrawlspaceInsulation,
  Solution.AtticFloorInsulation,
  Solution.Miscellaneous, // not intended to be shown in results overview
  Solution.Sedum, // not intended to be shown in results overview
  Solution.ChargingBox, // not intended to be shown in results overview
  Solution.UnderfloorHeating, // not intended to be shown in results overview
  Solution.WindowFrames, // not intended to be shown in results overview
];