const globalStructuredData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Energiebespaarders',
  legalName: 'De Energiebespaarders B.V.',
  url: 'https://energiebespaarders.nl',
  logo: 'https://energiebespaarders.nl/img/logomark.png',
  contactPoint: {
    '@type': 'ContactPoint',
    // telephone: '+31-85-2103977',
    contactType: 'customer service',
    availableLanguage: ['Dutch', 'English'],
  },
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Reguliersdwarsstraat 58A',
    postalCode: '1017BM',
    addressLocality: 'Amsterdam',
    addressRegion: 'Noord-Holland',
    addressCountry: 'NL',
  },
  currenciesAccepted: 'EUR',
  openingHours: 'Mo-Fr 09:00-17:30',
  sameAs: [
    'https://www.facebook.com/energiebespaarders',
    'https://www.linkedin.com/company/energiebespaarders',
    'https://twitter.com/energiebespaarders',
  ],
};

export default globalStructuredData;
