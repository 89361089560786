import React, { useContext, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

type ContextValue = [number, Dispatch<SetStateAction<number>> | null];

export const NotificationCounterContext = React.createContext<ContextValue>([0, null]);

export const NotificationCounterProvider: React.FC = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const contextValue: ContextValue = [notificationCount, setNotificationCount];
  return (
    <NotificationCounterContext.Provider value={contextValue}>
      {children}
    </NotificationCounterContext.Provider>
  );
};

export function useNotificationCounter() {
  const [notificationCount, setNotificationCount] = useContext(NotificationCounterContext);

  const localCount = Number(
    typeof window !== 'undefined' ? window.localStorage?.getItem('notificationCounter') ?? '' : '',
  );

  const handleSetNotificationCount = (count: number) => {
    setNotificationCount && setNotificationCount(count);
    // Also store notificationCounter in localStorage so we can reinitialize correctly on reload, see Initialize component
    window.localStorage.setItem('notificationCounter', count.toString());
  };
  return {
    notificationCount: localCount || notificationCount,
    setNotificationCount: handleSetNotificationCount,
  };
}
