import { SOLUTIONS_NL } from '@energiebespaarders/constants';
import _ from 'lodash';
import type { DataLayerEventNames, DataLayerParameters } from '~/types/analytics';
import type { mySingleQuote_quoteById_items } from '~/types/generated/mySingleQuote';
import type { resultsPage_house_estimates } from '~/types/generated/resultsPage';

/** Can also push to data layer without any event, just to add variables or overwrite existing ones */
export type DataLayerObject = { event?: DataLayerEventNames } & Partial<
  Record<DataLayerParameters, any>
>;

/**
 * Pushes event data into the data layer for GTM. Can only be read if a visitor has accepted cookies and has a GTM clientId (found in _ga cookie).
 * This method is only used directly for basic initialization and global session / page data. Otherwise to be called through our useEventTracker hook that can use Measurement Protocol as a backup.
 *
 * NOTE: Pushing `undefined` resets the value in the dataLayer
 * NOTE: Google recommends using the `gtag` function (globally available and properly typed)
 */
export const pushToDataLayer = (dataObj: DataLayerObject) => {
  if (navigator.webdriver) return; // Prevent GTM from tracking bots

  if (typeof window === 'undefined' || !window.dataLayer) {
    return console.warn('window.dataLayer missing for GTM');
  }

  // Prevent duplicate objects in the dataLayer
  const dataLayerObjects = window.dataLayer || [];
  if (dataLayerObjects.some(obj => _.isEqual(obj, dataObj))) return;

  if (Object.keys(dataObj).length > 0) window.dataLayer.push(dataObj);
};

/**
 * These variables are kept in the data layer, even when the page changes, until the user leaves the site.
 * This way we can continuously access these variables in GTM for all kinds of events, until they are overwritten.
 */
const persistentVariables: DataLayerParameters[] = [
  'user_type',
  'house_id',
  'house_type',
  'zip',
  'construction_year',
  'product_interests',
  'consent_status',
];

/**
 * Resets the data layer for page-specific events by setting all page-specific variables to `undefined`
 */
export const cleanDataLayer = () => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return console.warn('window.dataLayer missing for GTM');
  }

  // Keep the object, but reset it, except for persistent variables
  window.dataLayer.forEach(obj => {
    Object.keys(obj).forEach(key => {
      if (!persistentVariables.includes(key as DataLayerParameters)) {
        obj[key] = undefined;
      }
    });
  });
};

type SolutionEstimate = {
  solution: string; // NL
  yearly_saving: number;
  investment: number;
};

/** Returns SolutionEstimate objects for GTM dataLayer */
export const parseSolutionEstimatesForGTM = (
  estimates: (Omit<
    resultsPage_house_estimates,
    '__typename' | 'amount' | 'isInstallable' | 'feasibility'
  > & { feasibility?: { status: string } })[],
): SolutionEstimate[] => {
  return estimates.map(e => ({
    solution: SOLUTIONS_NL[e.solution],
    investment: Math.round(e.investment),
    yearly_saving: Math.round(e.energyDelta.money),
    ...(e.feasibility ? { feasibility: e.feasibility.status } : {}),
  }));
};

type QuoteItem = {
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
  item_brand?: string;
};

export const parseQuoteItemsForGTM = (
  quoteItems: readonly mySingleQuote_quoteById_items[],
): QuoteItem[] => {
  return quoteItems.map(item => ({
    item_id: item.product.id,
    item_name: item.product.title,
    price: item.retailPrice,
    quantity: item.amount,
    ...('brand' in item.product ? { item_brand: item.product.brand?.name } : {}),
  }));
};
