import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client';
import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import * as cookie from 'cookie';
import localforage from 'localforage';
import { useMemo } from 'react';
import config from '../config';
import possibleTypes from '../possibleTypes.json';
import typePolicies from '../typePolicies.json';
import cleanTypenameFieldLink from './cleanTypenameFieldLink';
import type { GetServerSidePropsContext, NextPageContext } from 'next';
import type { NormalizedCacheObject } from '@apollo/client/cache';
import type { DefaultContext, GraphQLRequest } from '@apollo/client';

const isServer = typeof window === 'undefined';

/** Allows server-side query/mutations to access the SetCookie set on responses from the graphql API */
export type SetCookieCapturer = { setCookie?: string };
export type ApolloContextInput = {
  /** Setting to true will avoid creating a lead and a lead session in graphql-api: no SetCookie header will be set */
  requiresLeadInitialization?: boolean;
  /** Can be used to access the SetCookie header on a response from graphql-api after the request has completed */
  setCookieCapturer?: SetCookieCapturer;
};

/**
 * NEXT JS APOLLO CLIENT
 */

let apolloClient: ApolloClient<NormalizedCacheObject>;

const cookieEnv = config.isProduction ? 'production' : config.isStaging ? 'staging' : 'local';
const cookieName = `${config.clientName}_${cookieEnv}_sessId`;

export function createApolloClient(context?: NextPageContext | GetServerSidePropsContext) {
  localforage.config({
    storeName: 'Energiebespaarders',
  });

  const authLink = setContext((_, { headers, requiresLeadInitialization }) => {
    // On the Next server, we can access the cookie from the request by the client and re-use it
    // since the cookie set by graphql-api is set on energiebespaarders.nl (where the Next server runs)
    const serverHeaderSessId = cookie.parse(context?.req?.headers?.cookie || '')[cookieName] || '';

    return {
      headers: {
        ...headers,
        ...(serverHeaderSessId ? { 'server-side-session-id': serverHeaderSessId } : {}),
        // For certain operations (e.g. login with magic link on server side), no lead should be initialized
        'X-Requires-Session': Boolean(requiresLeadInitialization ?? true),
        'apollographql-client-name': config.clientName,
        'apollographql-client-version': config.version,
      },
    };
  });

  // When a cookie is set by graphql-api on the Next server,
  // we need to proxy the cookie to the browser by setting it on the Next response.
  // E.g. needed for GET_ME (which may initialize a new Lead session) and the MagicLink login mutation
  const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const context = operation.getContext();
      const setCookie = context.response.headers.get('set-cookie');
      const setCookieCapturer = (context as ApolloContextInput).setCookieCapturer;

      if (setCookie && setCookieCapturer) {
        // Store the cookie that was set on the context, so it can be used wherever after a query/mutation is performed
        setCookieCapturer.setCookie = setCookie;
        operation.setContext(context);
      }

      return response;
    });
  });

  // TODO: This can be added later - not necessary for MVP
  // -> This is from IA: Every error has an error code there, used to show a human readable error message
  // const errorLink = new ApolloLink((operation, forward) => {
  //   return forward(operation).map(response => {
  //     if (response.errors) {
  //       response.errors.forEach(({ message }, index) => {
  //         // Catch each error that has an error code and translate it to a human
  //         // readable message
  //         const code = getCodeFromErrorMessage(message);
  //         if (code && translations.nl[code as ErrorCodes]) {
  //           // TODO:  Get the locale from the response - fine like this for now
  //           response.errors![index].message = translations.nl[code as ErrorCodes];
  //         }
  //       });
  //     }
  //     return response;
  //   });
  // });

  // TODO: Custom auth:: https://www.apollographql.com/docs/link/links/batch-http.html#custom-auth
  const httpLink = new HttpLink({ uri: config.urls.graphql, credentials: 'include' });

  /**
   * File stuff from EBH
   */
  const isFile = (value: any) =>
    (typeof FileList !== 'undefined' && value instanceof FileList) ||
    (typeof File !== 'undefined' && value instanceof File) ||
    (typeof Blob !== 'undefined' && value instanceof Blob) ||
    (typeof File !== 'undefined' &&
      typeof Blob !== 'undefined' &&
      Array.isArray(value) &&
      value.some(v => v instanceof File || v instanceof Blob));
  const isUpload = ({ variables }: any) => Object.values(variables).some(isFile);
  const isNotUpload = (input: any) => !isUpload(input);
  const uploadLink = createUploadLink({
    uri: config.urls.graphql,
    credentials: 'include',
    headers: { 'Apollo-Require-Preflight': 'true' }, // required for CSRF prevention & graphql-upload, see https://www.apollographql.com/docs/apollo-server/security/cors/#graphql-upload
  });

  // The type for uploads is needed to determine the split in the terminal link
  const filteredCleanTypenameFieldLink = split(isNotUpload, cleanTypenameFieldLink);

  // Set up the terminal link: differentiate between upload an non-upload requests
  // NOTE: types are outdated for apollo-upload-link
  const terminalLink = split(isUpload, uploadLink as any, httpLink);

  const isBrowser = typeof window !== 'undefined';

  const resetToken = onError(({ networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  /**
   * Building blocks
   */

  const bbLink = setContext((req: GraphQLRequest, { headers }: DefaultContext) => {
    if (req.operationName === 'me' || req.operationName === 'updateLead') return req;

    const clientParams =
      typeof location !== 'undefined'
        ? Object.fromEntries(new URLSearchParams(location.search))
        : undefined;

    const params = clientParams ?? context?.query ?? {};

    if (params.bbRef && params.houseId) {
      return {
        headers: {
          ...headers,
          'X-BB-Token': params.bbRef,
          'X-BB-HouseId': params.houseId,
        },
      } as DefaultContext;
    }
    return req;
  });

  const link = ApolloLink.from([
    filteredCleanTypenameFieldLink,
    resetToken,
    authLink,
    bbLink,
    isServer ? afterwareLink.concat(terminalLink) : terminalLink,
  ]);

  const cache = new InMemoryCache({
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Progress':
          return object.houseId;
        default:
          return defaultDataIdFromObject(object) as any;
      }
    },
    // Generated using the utilities/getPossibleTypes.js
    possibleTypes,
    // Generated using the utilities/getMergeTypePolicies.js
    typePolicies,
  });

  // Had a chat with Carsten: not necessarily needed, there are very few queries that would benefit from caching
  // It would require making this function async: requires a loading state on initial render, too complicated for now
  // const persistor = new CachePersistor({ cache, storage: new LocalForageWrapper(localforage) });
  // try {
  //   await persistor.restore();
  // } catch (error) {
  //   console.error('Error restoring Apollo cache', error);
  // }

  return new ApolloClient({
    name: config.clientName,
    connectToDevTools: isBrowser && config.isDeveloping,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link,
    cache,
    version: config.version,
  });
}

/**
 * Create an apollo client that can be used to query/mutate GQL server
 */
export function initializeApollo(
  initialState: NormalizedCacheObject | null = null,
  context?: NextPageContext | GetServerSidePropsContext,
) {
  const _apolloClient = apolloClient ?? createApolloClient(context);
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // On the server, don't persist the apollo client: unsafe if shared between users
  if (typeof window === 'undefined') return _apolloClient;

  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

/**
 * Add the apollo cache to the request
 * CURRENTLY UNUSED, SEE INSTALLER-ACCOUNT FOR ITS USE
 */
export const APOLLO_STATE_PROP_NAME = 'initialApolloState';

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

/**
 * Hook that returns an apollo client (new one if client, existing one if server)
 */
export function useApollo(initialState: NormalizedCacheObject) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
