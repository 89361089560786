import { useApolloClient, useMutation } from '@apollo/client';
import { configureScope as SentryConfigureScope } from '@sentry/nextjs';
import localforage from 'localforage';
import { useCallback, useMemo, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import type { UTM } from '~/components/_app/utmUtils';
import { isUtmEmpty, stringifyUtm } from '~/components/_app/utmUtils';
import { pushToDataLayer } from '~/utils/analytics';
import config from '../config';
import { REINITIALIZE_SESSION } from '../queries/initialize';
import { useActiveHouseId } from './useActiveHouseId';
import type {
  reinitializeSession,
  reinitializeSessionVariables,
} from '../types/generated/reinitializeSession';

export function useClearSession() {
  const [isClearing, setIsClearing] = useState(false);
  const [sessionCleared, setSessionCleared] = useState(false);
  const client = useApolloClient();
  const { setActiveHouseId } = useActiveHouseId();
  const intercom = useIntercom();

  const [reinitializeSessionMutation] = useMutation<
    reinitializeSession,
    reinitializeSessionVariables
  >(REINITIALIZE_SESSION);

  const localUTM = useMemo(
    () =>
      typeof window !== 'undefined'
        ? window.localStorage.getItem('urlUtm') &&
          JSON.parse(window.localStorage.getItem('urlUtm') || '{}')
        : undefined,
    [],
  );

  /**
   * Calling this clears the Apollo store, resets the contexts to default values and redirects, leaving the user with a brand new session on a landingPage of your choice
   * NOTE: It restores the UTM that was stored in the localStorage or uses the one passed in
   */
  const clearSession = useCallback(
    async (redirect = '/', utm?: UTM | null, callback?: (args?: any) => void) => {
      if (isClearing) return console.log('Session is clearing.');
      if (sessionCleared) return console.log('Session already cleared.');
      console.debug('Clearing session...');

      setIsClearing(true);

      const initializeNewSession = async () => {
        await reinitializeSessionMutation();
        localStorage.clear();
        void localforage.clear();
        await client.clearStore();

        intercom.shutdown();

        if (config.isProduction) {
          SentryConfigureScope(scope => scope.setUser({}));
          pushToDataLayer({ user_id: undefined }); // Remove from data layer
        }

        setActiveHouseId('');
        setIsClearing(false);
        setSessionCleared(true);
      };

      await initializeNewSession().then(() => {
        callback?.();
        const utmString = !isUtmEmpty(utm) ? stringifyUtm(utm) : stringifyUtm(localUTM);
        window.location.href = `${window.location.origin}${redirect}${
          utmString ? `?${utmString}` : ''
        }`;
      });
    },
    [
      isClearing,
      sessionCleared,
      reinitializeSessionMutation,
      client,
      setActiveHouseId,
      intercom,
      localUTM,
    ],
  );

  return { clearSession, sessionCleared, setSessionCleared };
}
