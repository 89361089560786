import React from 'react';
import { AbTestEntryProvider } from '~/hooks/useABTest';
import { ActiveHouseIdProvider } from '~/hooks/useActiveHouseId';
import { ActiveHouseProgressProvider } from '~/hooks/useActiveHouseProgress';
import { ContactModalProvider } from '~/hooks/useContactModal';
import { FinancingConfigProvider } from '~/hooks/useFinancingConfig';
import { IsAuthenticatedProvider } from '~/hooks/useIsAuthenticated';
import { IsInitializingProvider } from '~/hooks/useIsInitializing';
import { LeadEmailProvider } from '~/hooks/useLeadEmail';
import type { Me } from '~/hooks/useMe';
import { MeProvider } from '~/hooks/useMe';
import { NotificationCounterProvider } from '~/hooks/useNotificationCounter';
import { PersonalEnergyPricesProvider } from '~/hooks/usePersonalEnergyPrices';
import { ToasterProvider } from '~/hooks/useToaster';
import { SiteLocale } from '~/types/graphql-cms-global-types';
import { LocaleProvider } from 'src/datoCMS/useLocale';

const ComposedContextProvider: React.FC<{ initialMe: Me }> = ({ children, initialMe }) => {
  return (
    <LocaleProvider initialValue={SiteLocale.nl_NL}>
      <ToasterProvider>
        <IsInitializingProvider>
          <MeProvider initialMe={initialMe}>
            <ContactModalProvider>
              <IsAuthenticatedProvider>
                <ActiveHouseIdProvider>
                  <AbTestEntryProvider>
                    <LeadEmailProvider>
                      <FinancingConfigProvider>
                        <NotificationCounterProvider>
                          <PersonalEnergyPricesProvider>
                            <ActiveHouseProgressProvider>{children}</ActiveHouseProgressProvider>
                          </PersonalEnergyPricesProvider>
                        </NotificationCounterProvider>
                      </FinancingConfigProvider>
                    </LeadEmailProvider>
                  </AbTestEntryProvider>
                </ActiveHouseIdProvider>
              </IsAuthenticatedProvider>
            </ContactModalProvider>
          </MeProvider>
        </IsInitializingProvider>
      </ToasterProvider>
    </LocaleProvider>
  );
};

export default ComposedContextProvider;
